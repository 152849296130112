import { WealthHealthStepIndex } from '@acorn/feature-application';
import { Application, ApplicationSubStep, CustomerUser, WealthHealthStep } from '@acorn/util';

export function getSelectedCategoryIndex(
  isFinishedStep: boolean,
  currentSubStep: ApplicationSubStep,
  editable: boolean
) {
  const step = editable ? WealthHealthStep.WealthHappy  : WealthHealthStep.WealthBuildingBlock;
  return isFinishedStep
    ? WealthHealthStepIndex[step]
    : WealthHealthStepIndex[<WealthHealthStep>currentSubStep];
}

export function isWealthScoreStep(currentStepIndex: number | any, editable: boolean): boolean {
  const step = editable ? WealthHealthStep.WealthHappy : WealthHealthStep.WealthScore;
  return (
    currentStepIndex === WealthHealthStepIndex[step]
  );
}
