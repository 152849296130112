<acorn-application-stepper
    [currentUser]="currentUser!"
    [latestApplicationStep]="latestApplicationStep()!"
    [selectedIndex]="applicationStepIndex()"
    [subscriptionPlan]="subscriptionPlan()"
    [dashboardLastStep]="this.dashboardLastStep"
    [onlyAboutYou]="onlyAboutYou"
    [isFromSummary]="currentUser?.currentApplication?.isStepFromSummary!"
    (selectedIndexChange)="stepperChanged($event, false, false, true)"
    [isFileNotes]="isFileNotes"
    [isDashboardApp]="isDashboardApp"
>
    <cdk-step label="Summary">
        <acorn-summary
            *ngIf="isFileNotes || (isDashboardApp && applicationStep() === ApplicationStep.GettingStarted)"
            [isFileNotes]="isFileNotes"
            [isDashboardApp]="isDashboardApp"
            [customerUser]="currentUser!"
            (showApplication)="showApplication($event)"
        ></acorn-summary>
    </cdk-step>

    <cdk-step label="About You" *ngIf="currentUser && currentUser.currentApplication">
        <acorn-about-you
            *ngIf="isReadyForAboutYou() && applicationStep() === ApplicationStep.AboutYou"
            class="grid-container"
            [onlyAboutYou]="onlyAboutYou"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            [categories]="aboutYouCategories()"
            [applicationId]="currentUser.currentApplication?.id!"
            [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
            (syncApplicationUsers)="onSyncApplicationUsers($event)"
            [questions]="aboutYouQuestions()"
            [answerSelections]="answerSelections()"
            [applicationAboutYou]="applicationAboutYou()"
            [allowNavigateToHome]="allowNavigateToHome"
            [currentApplication]="currentUser.currentApplication"
            [applicationPlan]="currentUser?.currentApplication?.applicationMode"
            (back)="backToSummaryPage()"
            [isFileNotes]="isFileNotes"
            [isDisable]="isDisable"
            [isAppComplete]="this.isAppComplete"
            (syncApplicationUsers)="onSyncApplicationUsers($event)"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.Goal, null)"
        ></acorn-about-you>
    </cdk-step>

    <cdk-step label="Your Goals" *ngIf="currentUser">
        <acorn-feature-goals
            [currentApplication]="currentUser?.currentApplication!"
            [applicationGoal]="applicationGoal()!"
            [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
            [dashboardLastStep]="this.dashboardLastStep"
            [latestApplicationStep]="latestApplicationStep()!"
            (back)="updateProgress(false, ApplicationStep.AboutYou, null)"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.FinancialSnapshot, null)"
            (adjustStep)="updateProgress(false, ApplicationStep.WealthCoach, null)"
            (updatedProgress)="updateCurrentProgress($event)"
            [goalStep]="goalStep()!"
            [partStep]="partStep()!"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            [isWelcomeBackEditable]="isWelcomeBackEditable"
            (triggerWebhook)="triggerWebhook($event)"
            [isDashboardApp]="isDashboardApp"
        >
        </acorn-feature-goals>
    </cdk-step>

    <cdk-step label="Financial Situation" *ngIf="currentUser && currentUser.currentApplication?.applicationUsers">
        <acorn-feature-financial-situation
            *ngIf="financialSituation() && applicationStep() === ApplicationStep.FinancialSnapshot"
            [currentApplication]="currentUser?.currentApplication!"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            [categories]="financialSituation()!.categories"
            [applicationId]="currentUser.currentApplication?.id!"
            [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
            [questions]="financialSituation()!.questions"
            [answers]="financialSituation()!.answers"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            [answerSelections]="financialSituation()!.answerSelections"
            (back)="updateProgress(false, ApplicationStep.Goal, null,null , false ,false, true)"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.RiskTolerance, null)"
            (triggerWebhook)="triggerWebhook($event)"
        ></acorn-feature-financial-situation>
    </cdk-step>

    <cdk-step label="Risk Tolerance" *ngIf="currentUser && currentUser.currentApplication">
        <acorn-risk-tolerance
            *ngIf="isReadyForRisk() && applicationStep() === ApplicationStep.RiskTolerance"
            class="grid-container"
            [currentUser]="currentUser"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            [categories]="riskToleranceCategories()"
            [applicationId]="currentUser.currentApplication?.id!"
            [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
            [questions]="riskToleranceQuestions()"
            [answerSelections]="riskToleranceAnswerSelections()"
            [applicationAboutYou]="applicationRiskTolerance()"
            [currentApplication]="currentUser.currentApplication"
            [applicationPlan]="currentUser?.currentApplication?.applicationMode"
            (back)="updateProgress(false, ApplicationStep.FinancialSnapshot, null)"
            [isAppComplete]="this.isAppComplete"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.WealthHealth, null)"
            [isFileNotes]="isFileNotes"
        ></acorn-risk-tolerance>
    </cdk-step>

    <cdk-step label="Wealth Health">
        <acorn-wealth-health
            *ngIf="isReadyForWealth() && applicationStep() === ApplicationStep.WealthHealth"
            class="grid-container"
            [categories]="wealthCategories()"
            [applicationId]="currentUser.currentApplication?.id!"
            [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
            [questions]="wealthQuestions()"
            [answerSelections]="wealthAnswerSelections()"
            [applicationAboutYou]="applicationWealth()"
            [currentApplication]="currentUser.currentApplication"
            [currentUser]="currentUser"
            [isDashboardApp]="isDashboardApp"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            (back)="updateProgress(false, ApplicationStep.RiskTolerance, null,null , false ,false, true)"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.FinancialProjection, null)"
            (backToSummary)="backToSummaryPage()"
        ></acorn-wealth-health>
    </cdk-step>

    <cdk-step label="Financial Projection" *ngIf="currentUser">
        <acorn-feature-financial-projection
            *ngIf="applicationStep() === ApplicationStep.FinancialProjection"
            [isManualFlow]="currentUser.currentApplication?.isManualFlow"
            [applicationId]="currentUser.currentApplication?.id!"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            (back)="updateProgress(false, ApplicationStep.WealthHealth, null ,null , false ,false, true)"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.WealthStrategyOptions, null)"
            (adjustStep)="updateProgress(false, ApplicationStep.Goal, GoalStep.RoundVerify, null, false, true ,true)"
            (financialSituation)="updateProgress(false, ApplicationStep.FinancialSnapshot, null, null, true ,false ,true)"
            (isCalculationStopped)="isCalculationStopped($event)"
            (movetoPrevious)="movetoPrevious()"
        ></acorn-feature-financial-projection>
    </cdk-step>
    <cdk-step label="Wealth Strategy Options">
        <acorn-feature-wealth-strategy-options
            *ngIf="applicationStep() === ApplicationStep.WealthStrategyOptions"
            [onlyWealthstrategyOptions]="onlyWealthstrategyOptions"
            [currentUser]="currentUser"
            [currentSubStep]="currentSubStep()!"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            [applicationId]="currentUser.currentApplication?.id!"
            [latestApplicationStep]="latestApplicationStep()!"
            [dashboardLastStep]="this.dashboardLastStep"
            (dashboardEmit)="dashboardEmit()"
            (back)="updateProgress(false, ApplicationStep.FinancialProjection, null,null , false ,false, true)"
            (finishedStep)="updateProgress($event.isUpdateApplicationProgress, ApplicationStep.WealthCoach, null)"
        ></acorn-feature-wealth-strategy-options>
    </cdk-step>
    <cdk-step label="Wealth Coach">
        <acorn-wealth-coach
            *ngIf="isShowWealthCoach()"
            [currentUser]="currentUser"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="this.isAppComplete"
            [isDashboardApp]="isDashboardApp"
            [calendarLink]="calendarLink"
            (back)="updateProgress(false, ApplicationStep.WealthStrategyOptions, null ,null, false ,false, true)"
            (backToGoal)="onBackToGoal()"
        ></acorn-wealth-coach>
    </cdk-step>
</acorn-application-stepper>
