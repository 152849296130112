import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent, CheckboxComponent, NavigationComponent, TooltipDirective } from '@acorn/common-ui';
import { FormControl, FormGroup, FormRecord, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskDirective } from 'ngx-mask';
import { AnswerSelection, AboutYouQuestion, AnswerType, ERROR_MESSAGE, AboutYouAnswerResponse, AboutYouUserAnswer } from '@acorn/util';
import { MatDialog } from '@angular/material/dialog';
import { BaseAboutYouContentComponent } from '../base-about-you-content';
import { forEach } from 'lodash';

@Component({
    selector: 'acorn-common-answer-new',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        IconSpriteModule,
        AlertComponent,
        CheckboxComponent,
        MatFormFieldModule,
        MatSelectModule,
        MatExpansionModule,
        NavigationComponent,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        CheckboxComponent,
        MatTooltipModule,
        TooltipDirective,
        NgxMaskDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './common-answer-new.component.html',
    styleUrls: ['./common-answer-new.component.scss', '../base-about-you-content/base-about-you-content.component.scss'],
})
export class CommonAnswerNewComponent extends BaseAboutYouContentComponent implements OnChanges {
    #dialog = inject(MatDialog);

    @Input({ required: true }) answerSelections: AnswerSelection[] = [];
    @Input({ required: true }) secondaryQuestion!: Array<AboutYouQuestion>;
    @Input({ required: true }) primaryQuestion!: AboutYouQuestion;
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;

    protected readonly AnswerType = AnswerType;
    protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

    selections = signal<AnswerSelection[]>([]);
    secSelections = signal<AnswerSelection[]>([]);

    formRecord: FormRecord<FormControl<any>> = new FormRecord({});
    showSecondQuestion = false;
    applicationUserId!: string;

    get tooltip() {
        return this.secondaryQuestion[0]?.question?.description;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['currentCategory'] || changes['questions'] || changes['primaryQuestion']) {
            this.#calculateTemplate();
        }
    }

    #calculateTemplate(): void {
        this.selections.set(
            this.answerSelections.filter((answerSelection) => answerSelection.questionId === this.primaryQuestion.questionId)
        );

        this.secSelections.set(
            this.answerSelections.filter((answerSelection) => answerSelection.questionId === this.secondaryQuestion[1].questionId)
        );

        this.formRecord = new FormGroup({});
        for (const applicationUser of this.applicationUsers) {
            const key = applicationUser.id;

            this.applicationUserId = key;

            this.formTemplate[key] = {
                applicationUserId: key,
                applicationUserName: applicationUser.firstName,
                order: applicationUser.order,
            };

            const formControl = new FormControl(
                this.#getInitialControlValue(this.primaryQuestion.question.answerType, this.#getPrimaryAnswers(key)),
                this.#getValidatorsByAnswerType(this.primaryQuestion.question.answerType, this.primaryQuestion.isRequired)
            );

            this.formRecord.addControl(key, formControl);

            const primaryValue = formControl.value;
            if (primaryValue === '5dcb4a42-8af4-42f3-bb2c-a70951f9bfa0') {
                const secondaryAnswers = this.#getPrimarySecondaryAnswers(key);

                const secondaryControl = new FormControl(
                    this.#getSecondaryInitialControlValue(this.secondaryQuestion[1].question.answerType, secondaryAnswers),
                    this.#getValidatorsByAnswerType(this.secondaryQuestion[1].question.answerType, this.secondaryQuestion[1].isRequired)
                );

                this.formRecord.addControl(`${key}-secondary`, secondaryControl);
            }

            if (!this.isDualRespondent) {
                break;
            }
        }
    }

    #getInitialControlValue(answerType: AnswerType, selectedAnswers: AboutYouAnswerResponse[]): any {
        switch (answerType) {
            case AnswerType.Radio: {
                const selectedAnswer = selectedAnswers.find((answer) => answer.questionId === this.primaryQuestion.questionId);

                const selected = this.selections().find((selection) => selection.content === selectedAnswer?.answerContent);

                if (!selected) {
                    return '';
                }

                return selected.id;
            }
        }
    }

    #getSecondaryInitialControlValue(answerType: AnswerType, selectedAnswers: AboutYouAnswerResponse[]): any {
        switch (answerType) {
            case AnswerType.Radio: {
                const selectedAnswer = selectedAnswers.find((answer) => answer.questionId === this.secondaryQuestion[1].questionId);
                const selected = this.secSelections().find((selection) => selection.content === selectedAnswer?.answerContent);

                if (!selected) {
                    return '';
                }

                return selected.id;
            }
        }
    }

    #getPrimaryAnswers(applicationUserId: string): AboutYouAnswerResponse[] {
        if (!this.applicationUsers.length || !this.applicationAboutYou.length) {
            return [];
        }

        return this.applicationAboutYou.find((item) => item.applicationUserId === applicationUserId)?.answers || [];
    }

    #getPrimarySecondaryAnswers(applicationUserId: string): AboutYouAnswerResponse[] {
        if (!this.applicationUsers.length || !this.applicationAboutYou.length) {
            return [];
        }

        const userAnswers = this.applicationAboutYou.filter((item) => item.applicationUserId === applicationUserId);

        const allAnswers: AboutYouAnswerResponse[] = userAnswers.flatMap((item) => item.answers);

        return allAnswers;
    }

    #getValidatorsByAnswerType(answerType: AnswerType, isRequired: boolean) {
        if (!isRequired) {
            return [];
        }

        switch (answerType) {
            default: {
                return [Validators.required];
            }
        }
    }

    convertFormValueToUserAnswer(): AboutYouUserAnswer[] {
        const userAnswers: AboutYouUserAnswer[] = [];

        const applicationUserId = Object.keys(this.formRecord.controls)[0];
        const formRecord = this.formRecord.controls[applicationUserId];

        let answerContent = '';
        let answerOrder = 0;
        let isKOQuestion = false;
        let isShowWarning = false;

        const selectedAnswer = this.selections().find((selection) => selection.id === formRecord.value);

        answerContent = selectedAnswer?.content || '';
        answerOrder = selectedAnswer?.order || 0;
        isShowWarning = selectedAnswer?.isShowWarning || false;

        userAnswers.push({
            isKOQuestion,
            applicationUserId: applicationUserId,
            isShowWarning: isShowWarning,
            answers: [
                {
                    content: answerContent || this.primaryQuestion.defaultValue || '',
                    questionId: this.primaryQuestion.questionId,
                    isRequired: this.primaryQuestion.isRequired,
                    order: answerOrder,
                },
            ],
        });

        const secondaryControl = this.formRecord.controls[`${applicationUserId}-secondary`];
        if (secondaryControl) {
            const secondarySelectedAnswer = this.secSelections().find((selection) => selection.id === secondaryControl.value);

            if (secondarySelectedAnswer) {
                userAnswers.push({
                    isKOQuestion: false,
                    applicationUserId,
                    isShowWarning: secondarySelectedAnswer?.isShowWarning || false,
                    answers: [
                        {
                            content: secondarySelectedAnswer.content || '',
                            questionId: this.secondaryQuestion[1]?.questionId,
                            isRequired: this.secondaryQuestion[1]?.isRequired,
                            order: secondarySelectedAnswer?.order || 0,
                        },
                    ],
                });
            }
        }

        return userAnswers;
    }

    onSubmit(): void {
        if (this.isFileNotes || this.isAppComplete) {
            this.next.emit();
            return;
        }

        this.formRecord.markAsDirty();
        this.isSubmitted.set(true);
        this.errorMessages.set([]);
        this.serverErrorMessage.set('');
        if (this.formRecord.invalid) {
            this.#getAllErrorMessages();
            return;
        }

        const userAnswers = this.convertFormValueToUserAnswer();
        this.updateNewAnswer(userAnswers , true);
    }

    onRadioChanged(event: any, key: string): void {
        this.showSecondQuestion = event.value === '5dcb4a42-8af4-42f3-bb2c-a70951f9bfa0';

        if (!this.showSecondQuestion) {
            this.removeSecondaryQuestionControls();
        } else {
            this.addSecondaryQuestionControl(key);
        }

        this.formRecord.controls[key]?.markAsDirty();

        const secondaryControl = this.formRecord.controls[`${key}-secondary`];
        if (secondaryControl) {
            secondaryControl.markAsDirty();
        }
    }

    removeSecondaryQuestionControls(): void {
        Object.keys(this.formRecord.controls).forEach((key) => {
            if (key.includes('-secondary')) {
                this.formRecord.removeControl(key);
            }
        });
    }

    addSecondaryQuestionControl(key: string): void {
        const secondaryKey = `${key}-secondary`;

        if (!this.formRecord.controls[secondaryKey]) {
            const secondaryFormControl = new FormControl('');

            this.formRecord.addControl(secondaryKey, secondaryFormControl);

            console.log(`Added secondary form control for ${secondaryKey}`, this.formRecord);
        }
    }

    // addSecondaryQuestionControl(key: string): void {
    //     const secondaryKey = `${key}-secondary`;

    //     if (!this.formRecord.controls[secondaryKey]) {
    //         const secondaryFormControl = new FormControl('');

    //         // Add the secondary form control to the form record
    //         this.formRecord.addControl(secondaryKey, secondaryFormControl);

    //         // Optionally patch the secondary form control with an initial value
    //         const secondarySelectedAnswer = this.secSelections().find(
    //             (selection) => selection.id === 'desiredSecondaryValue'  // Replace with actual logic if needed
    //         );
    //         if (secondarySelectedAnswer) {
    //             secondaryFormControl.patchValue(secondarySelectedAnswer.id);
    //         }

    //         console.log(`Added secondary form control for ${secondaryKey}`, this.formRecord);
    //     }
    // }

    #getAllErrorMessages(): void {
        const messages: string[] = [];
        forEach(this.formRecord.controls, (control) => {
            if (control.hasError('required')) {
                messages.push(ERROR_MESSAGE.MAKE_A_SELECTION);
            }
        });

        this.errorMessages.set(messages);
    }

    #firstLetterLowercase(str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
}
