<mat-accordion>
  <mat-expansion-panel [expanded]="isExpand">
    <mat-expansion-panel-header>
      <mat-panel-title [title]="question!.question.content">
        {{ question!.question.content }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="questions"
      *ngFor="let formRecord of formArray.controls; let index = index"
    >
      <div class="sub-title">
        {{ question!.question.content }} #{{ index + 1 }}
      </div>

      <div *ngIf="question?.childrenQuestions?.length">
        <ng-container
          *ngFor="let item of question.childrenQuestions; last as isLast"
        >
          <acorn-single-question
            class="card-disabled"
            [expandDisabled]="true"
            [formGroup]="formRecord.controls[item.id]"
            [question]="item"
            [answerSelections]="answerSelections"
            [isDualRespondent]="isDualRespondent"
            [applicationUsers]="applicationUsers"
            [removePaddingHeader]="isDualRespondent"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="isAppComplete"
          ></acorn-single-question>

          <mat-divider *ngIf="hasDivider && !isLast"></mat-divider>
        </ng-container>
        <div
          *ngIf="isSubmitted && formRecord.hasError('allFieldRequired')"
          class="error-message message"
        >
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#exclamation-solid'"
            [width]="'24px'"
          ></svg-icon-sprite>
          {{ ERROR_MESSAGE.ALL_FIELDS_ARE_REQUIRED }}
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
