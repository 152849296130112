<mat-accordion>
  <mat-expansion-panel [disabled]="isDisabledExpansion" [expanded]="isExpand">
    <!--merge panel template-->
    <mat-expansion-panel-header
      *ngIf="isDisplayedMergedQuestion; else defaultPanelTemplate"
    >
      <mat-panel-title>
        {{ applicationUsers | displayUsersName }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template #defaultPanelTemplate>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span [title]="question!.question.content">
            {{ question!.question.content }}
          </span>

          {{ isDisabledExpansion ? '#' + index : '' }}
        </mat-panel-title>

        <button
          *ngIf="isDisabledExpansion"
          mat-stroked-button
          class="remove-button"
          (click)="removeClicked.emit()"
          [disabled]="isFileNotes || isAppComplete"
        >
          Remove
          <svg-icon-sprite
            iconPositionEnd
            [src]="'assets/sprites/sprite.svg#delete-bin'"
            [width]="'24px'"
          ></svg-icon-sprite>
        </button>
      </mat-expansion-panel-header>
    </ng-template>

    <form
      *ngIf="question?.childrenQuestions?.length && formRecord"
      class="questions"
      [formGroup]="formRecord"
    >
      <p
        class="merged-question"
        *ngIf="isDisplayedMergedQuestion"
        [title]="question!.question.content"
      >
        {{ question!.question.content }}
      </p>

      <ng-container
        *ngFor="let item of question.childrenQuestions; last as isLast"
      >
        <acorn-single-question
          class="card-disabled"
          [customeClass]="this.isBankAccountSavings "
          [expandDisabled]="true"
          [formGroup]="formRecord.controls[item.id]"
          [question]="item"
          [warningMessage]="warningMessage?.get(item.id)"
          [applicationKey]="applicationKey"
          [answerSelections]="answerSelections"
          [isDualRespondent]="isDualRespondent"
          [removePaddingHeader]="isDualRespondent"
          [isSubmitted]="isSubmitted"
          [applicationUsers]="applicationUsers"
          [isFileNotes]="isFileNotes"
          [isAppComplete]="isAppComplete"
          (removeWarningMessage)="onRemoveWarningMessage($event)"
        ></acorn-single-question>

        <mat-divider *ngIf="hasDivider && !isLast"></mat-divider>
      </ng-container>

      <div *ngIf="isShownErrorMessage" class="error-message message">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#exclamation-solid'"
          [width]="'24px'"
        ></svg-icon-sprite>
        {{ ERROR_MESSAGE.ALL_FIELDS_ARE_REQUIRED }}
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
