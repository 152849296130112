import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AnswerSelection,
  AnswerType,
  ApplicationUser,
  ERROR_MESSAGE,
  FinancialQuestion,
  ScreenSize,
} from '@acorn/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { SingleQuestionComponent } from '../single-question';
import { FormArray, FormRecord } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import {
  getRequiredToOtherQuestionsByKeys,
  handleLiabilityRealEstateChange,
} from '../../utils';

@Component({
  selector: 'acorn-nested-group-question',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    SingleQuestionComponent,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    IconSpriteModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './nested-group-question.component.html',
  styleUrls: ['./nested-group-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedGroupQuestionComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #cdRef = inject(ChangeDetectorRef);

  @HostBinding('class.dual-respondent')
  @Input()
  isDualRespondent? = false;

  @Input()
  hasDivider = true;

  @Input({ required: true }) question!: FinancialQuestion;
  @Input({ required: true }) formArray!: FormArray<FormRecord<any>>;
  @Input() answerSelections: AnswerSelection[] = [];
  @Input() isExpand = false;
  @Input() isSubmitted = false;
  @Input() applicationUsers: ApplicationUser[] = [];
  @Input() errorMessage: Map<string, string> | undefined;
  @Input() isFileNotes : boolean =  false;
  @Input() isAppComplete : boolean =  false;

  protected readonly ScreenSize = ScreenSize;
  protected readonly AnswerType = AnswerType;
  protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

  ngOnInit(): void {
    this.#handleListenToRealEstateChange();

    if(this.isAppComplete || this.isFileNotes){
        this.formArray.disable();
    }
  }

  #handleListenToRealEstateChange(): void {
    this.formArray.controls.forEach((formRecord) => {
      formRecord.valueChanges
        .pipe(debounceTime(300), takeUntilDestroyed(this.#destroyRef))
        .subscribe((dataChange) => {
          if (!this.question.childrenQuestions?.length) return;

          const requiredToOtherRealEstateQuestions =
            getRequiredToOtherQuestionsByKeys(
              ['isRequiredToInterestRate', 'isRequiredToRepaymentStructure'],
              this.question.childrenQuestions
            );

          if (requiredToOtherRealEstateQuestions.length) {
            handleLiabilityRealEstateChange(
              this.question.childrenQuestions,
              requiredToOtherRealEstateQuestions,
              dataChange,
              formRecord
            );
          }
          this.#cdRef.detectChanges();
        });
    });
  }
}
