import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    CUSTOM_ELEMENTS_SCHEMA,
    DestroyRef,
    effect,
    EventEmitter,
    HostBinding,
    inject,
    Injector,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
} from '@angular/core';
import { CommonModule, KeyValue, ViewportScroller } from '@angular/common';
import { FormGroup, FormRecord } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { debounceTime, filter, merge, startWith } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';

import { CdkStepperModule } from '@angular/cdk/stepper';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { forOwn, groupBy, has, inRange, isEmpty, isEqual, isNil, map, orderBy, reduce } from 'lodash';

import { AlertComponent, NavigationComponent } from '@acorn/common-ui';
import { CategoryService, FinancialSituationService } from '@acorn/data-access';

import {
    AnswerSelection,
    AnswerType,
    Application,
    ApplicationStep,
    ApplicationUser,
    Category,
    CategoryCode,
    CategoryStatus,
    DisplayMode,
    FinancialAnswer,
    FinancialCategoryType,
    FinancialFrequency,
    FinancialQuestion,
    FinancialSuperannuation,
    FinancialUserAnswer,
    KOQuestionType,
    LayoutService,
    Question,
    QuestionType,
    ScreenSize,
    WEBHOOK_STATUS,
} from '@acorn/util';

import { ApplicationStepIndex, findLatestStepIndex, getUpdatedCategoryStatus } from '@acorn/feature-application';

import {
    ArrayQuestionComponent,
    FinancialSnapshotComponent,
    GroupQuestionComponent,
    NestedGroupQuestionComponent,
    SingleQuestionComponent,
} from './ui';

import {
    KickoutDialogComponent,
    NestedVerticalStepperComponent,
    VerticalStepperComponent,
    WarningDialogComponent,
    WelcomeScreenComponent,
} from '../ui';

import { StepStatePipe } from '../util';

import {
    addWarningMessageRecord,
    AmountForm,
    deleteWarningMessageRecord,
    FinancialTemplateType,
    FormTemplate,
    getAmountFormById,
    getPopulatedValue,
    getIncomeControl,
    getWarningFormula,
    initFinancialForm,
    isNoWarningMessagesExist,
    LISTEN_FIELD_CHANGED_SPLIT_SEPARATOR,
    NO_DIVIDER_CATEGORIES,
    POPULATED_VALUE_REGEX,
    PopulatedField,
    PrimaryIncomeMessage,
    ReadonlyField,
} from './utils';

import { IsSingleQuestionExpandPipe } from './utils/pipes/is-single-question-expand.pipe';
import { IsExpandedExpansionPanelPipe } from './utils/pipes/is-expanded-expansion-panel.pipe';
import { Helper } from '../helpers/helper';

@Component({
    selector: 'acorn-feature-financial-situation',
    standalone: true,
    imports: [
        CommonModule,
        MatRadioModule,
        FinancialSnapshotComponent,
        NavigationComponent,
        WelcomeScreenComponent,
        CdkStepperModule,
        VerticalStepperComponent,
        NestedVerticalStepperComponent,
        MatCardModule,
        IconSpriteModule,
        SingleQuestionComponent,
        MatExpansionModule,
        AlertComponent,
        GroupQuestionComponent,
        ArrayQuestionComponent,
        NestedGroupQuestionComponent,
        StepStatePipe,
        IsSingleQuestionExpandPipe,
        IsExpandedExpansionPanelPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './feature-financial-situation.component.html',
    styleUrls: ['./feature-financial-situation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFinancialSituationComponent implements OnInit {
    layoutService = inject(LayoutService);
    #destroyRef = inject(DestroyRef);
    #categoryService = inject(CategoryService);
    #financialSituationService = inject(FinancialSituationService);
    #viewport = inject(ViewportScroller);
    #dialog = inject(MatDialog);
    #cdr = inject(ChangeDetectorRef);
    adjustSituationStatus = false;
    #helper = inject(Helper);

    @HostBinding('class.grid-container')
    get isDisplayGrid() {
        return !this.isProcessFinancialSnapshot();
    }

    @Input({ required: true }) currentApplication!: Application;
    @Input({ required: true }) applicationId!: string;
    @Input({ required: true }) applicationUsers!: ApplicationUser[];
    @Input({ required: true }) latestApplicationStep!: ApplicationStep;
    @Input({ required: true }) dashboardLastStep!: ApplicationStep;

    @Input({ required: true }) categories: Category[] = [];
    @Input({ required: true }) questions: FinancialQuestion[] = [];
    @Input({ required: true }) answerSelections: AnswerSelection[] = [];
    @Input({ required: true }) answers: FinancialUserAnswer[] = [];
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;

    @Output() back = new EventEmitter<void>();
    @Output() finishedStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    @Output() triggerWebhook = new EventEmitter<string>();

    protected readonly FinancialTemplateType = FinancialTemplateType;
    protected readonly AnswerType = AnswerType;
    protected readonly ScreenSize = ScreenSize;
    protected readonly FinancialSuperannuation = FinancialSuperannuation;
    protected readonly FinancialCategoryType = FinancialCategoryType;

    #isValidateAbleToNext = signal<boolean>(false);
    #isWarningAbleToNext = signal<boolean>(false);
    #canNext: Signal<boolean> = computed(() => this.#isWarningAbleToNext() && this.#isValidateAbleToNext());

    selectedStep = signal<number | undefined>(0);
    selectedChildStep = signal<number | undefined>(0);

    markAsSeen = signal(false);
    isLoading = signal<boolean>(false);
    errorMessage = signal<string>('');
    isSubmitted = signal<boolean>(false);
    warningMessages = signal<Map<string, Map<string, string>>>(new Map([]));
    errorMessages = signal<Map<string, Map<string, string>>>(new Map([]));
    selectedParentCategory = computed(() => {
        const selectedStepIndex = this.selectedStep();
        if (selectedStepIndex === undefined) {
            return null;
        }

        return this.categories[selectedStepIndex];
    });
    isProcessFinancialSnapshot = computed(() => this.selectedParentCategory()?.categoryCode === CategoryCode.FinancialSnapshot);
    selectedCategory = computed(() => {
        const parentCategory = this.selectedParentCategory();

        if (!parentCategory?.childItems?.length) {
            return null;
        }

        return parentCategory.childItems[this.selectedChildStep()!];
    });
    isDualRespondent = computed(() => this.selectedCategory()?.isDualRespondent && this.applicationUsers?.length === 2);

    selectedQuestions = computed<FinancialQuestion[]>(() => {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return [];
        }

        const filteredQuestions = this.#getFilteredQuestions(selectedCategory);

        if (!filteredQuestions.length) {
            return [];
        }

        const groupByParentId = groupBy(filteredQuestions, 'question.parentId');
        return groupByParentId['null'].map((question) => ({
            ...question,
            childrenQuestions: groupByParentId[question.questionId] || [],
        }));
    });

    formRecord = computed<FormRecord<FormRecord<any>>>(() => {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return new FormRecord({});
        }

        const formRecord: FormRecord<FormRecord<any>> = new FormRecord({});

        this.#createFormTemplate(this.selectedQuestions());

        for (const applicationUser of this.applicationUsers) {
            const applicationUserAnswer = this.answers.find((item) => item.applicationUserId === applicationUser.id);
            const [questionsFormRecord, populatedFields, readonlyFields] = initFinancialForm(
                this.selectedQuestions(),
                applicationUserAnswer?.answers
            );

            formRecord.addControl(applicationUser.id, questionsFormRecord);
            this.#initPopulatedFieldListeners(populatedFields, questionsFormRecord);
            this.#initReadonlyFieldListeners(readonlyFields, questionsFormRecord);

            if (!selectedCategory.isDualRespondent) {
                break;
            }
        }

        return formRecord;
    });
    hasDivider = computed<boolean>(() => {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return false;
        }

        return !NO_DIVIDER_CATEGORIES.includes(selectedCategory.categoryCode);
    });

    formTemplate: { [key: string]: FormTemplate } = {};
    showIncome = false;
    showLiabilities = false;
    showAssets = false;
    showExpenses = false;
    isFinancialQuestionId = "94409739-25b9-4762-a05b-e1a2cb8c28aa"

    constructor(private injector: Injector) {}

    ngOnInit() {
        // prod4
        // milestone2-changes

        this.adjustSituationStatus = this.categories.every((category) => category.status === CategoryStatus.Complete);

        // milestone2-changes
        if (this.adjustSituationStatus) {
            const incomeCategoryIndex = this.categories.findIndex((category) => category.name === 'Income');
            if (incomeCategoryIndex !== -1) {
                const primaryIncomeIndex = this.categories[incomeCategoryIndex].childItems?.findIndex(
                    (child) => child.name === 'Primary Income'
                );
                if (primaryIncomeIndex !== -1) {
                    this.showLiabilities = false;
                    this.showIncome = false;
                    this.showExpenses = false;
                    this.showAssets = false;
                    this.markAsSeen.set(true);
                    this.selectedStep.set(incomeCategoryIndex);
                    this.selectedChildStep.set(primaryIncomeIndex);
                    this.#cdr.detectChanges();
                    return;
                }
            }
        }

        const [stepIndex, childStepIndex] = findLatestStepIndex(this.categories);
        if (isNil(stepIndex) || isNil(childStepIndex)) {
            this.markAsSeen.set(false);
        } else {
            this.selectedStep.set(stepIndex);
            this.selectedChildStep.set(childStepIndex);
        }

        effect(
            () => {
                this.#handleCategoryIncomeChange();
            },
            { injector: this.injector }
        );

        if (stepIndex === 0 && childStepIndex === 0 && this.currentApplication.financialCategoryType === FinancialCategoryType.Income) {
            this.markAsSeen.set(false);
            this.showIncome = true;
            this.showLiabilities = false;
            this.showAssets = false;
            this.showExpenses = false;
        } else if (
            stepIndex === 0 &&
            childStepIndex === 0 &&
            this.currentApplication.financialCategoryType === FinancialCategoryType.Expenses
        ) {
            this.markAsSeen.set(false);
            this.showExpenses = true;
            this.showLiabilities = false;
            this.showAssets = false;
            this.showIncome = false;
        } else if (
            stepIndex === 0 &&
            childStepIndex === 0 &&
            this.currentApplication.financialCategoryType === FinancialCategoryType.Assets
        ) {
            this.markAsSeen.set(false);
            this.showAssets = true;
            this.showLiabilities = false;
            this.showIncome = false;
            this.showExpenses = false;
        } else if (
            stepIndex === 0 &&
            childStepIndex === 0 &&
            this.currentApplication.financialCategoryType === FinancialCategoryType.Liabilities
        ) {
            this.markAsSeen.set(false);
            this.showLiabilities = true;
            this.showIncome = false;
            this.showExpenses = false;
            this.showAssets = false;
        } else {
            this.showLiabilities = false;
            this.showIncome = false;
            this.showExpenses = false;
            this.showAssets = false;
            this.markAsSeen.set(true);
            this.selectedStep.set(stepIndex);
            this.selectedChildStep.set(childStepIndex);
        }

        if (this.currentApplication.financialCategoryType) {
            const categoryIndex = this.categories.findIndex((category) => category.name === this.currentApplication.financialCategoryType);

            if (categoryIndex !== -1) {
                this.selectedStep.set(categoryIndex);
            }
        }
    }

    applicationUserOrder = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number =>
        this.formTemplate[a.key].order > this.formTemplate[b.key].order ? 1 : 0;

    onSelectStep(index: number): void {
        this.isSubmitted.set(false);
        this.errorMessage.set('');

        this.selectedStep.set(index);
        this.selectedChildStep.set(0);
    }

    onSelectChildStep(index: number): void {
        this.isSubmitted.set(false);
        this.errorMessage.set('');

        this.selectedChildStep.set(index);
        this.#scrollToTop();
    }

    onHandleBack(): void {
        this.#scrollToTop();
        this.errorMessage.set('');
        const selectedCategory = this.selectedCategory();
        const selectedParentCategory = this.selectedParentCategory();

        if (!selectedCategory || !selectedParentCategory) {
            return;
        }

        if (selectedCategory.isFirstItem && selectedParentCategory.isFirstItem) {
            this.back.emit();
            return;
        }

        if (selectedCategory.isFirstItem) {
            const currentStepIndex = this.selectedStep()!;
            this.selectedStep.set(currentStepIndex - 1);

            const childStepIndex = this.categories[currentStepIndex - 1].childItems?.length!;
            this.selectedChildStep.set(childStepIndex - 1);

            return;
        }

        this.selectedChildStep.update((index) => index! - 1);
        this.#scrollToTop();
    }

    onHandleNext(isPropertyQuestion: boolean = false): void {
        const selectedCategory = this.selectedCategory();
        this.isSubmitted.set(false);

        if (!selectedCategory) {
            return;
        }

        // prod4
        if (this.currentApplication.financialCategoryType === FinancialCategoryType.Liabilities) {
            if (selectedCategory.isLastItem) {
                if (this.selectedParentCategory()?.isLastItem) {
                    const isUpdateApplicationProgress =
                        ApplicationStepIndex[this.latestApplicationStep] <= ApplicationStepIndex[ApplicationStep.RiskTolerance];

                    this.finishedStep.emit({ isUpdateApplicationProgress });
                } else {
                    this.selectedStep.update((step) => step! + 1);
                    this.selectedChildStep.set(0);
                }
            } else {
                this.selectedChildStep.update((index) => index! + 1);
            }
            return;
        }

        if (isPropertyQuestion) {
            this.selectedChildStep.update((index) => index! + 2);
        } else if (selectedCategory.isLastItem) {
            window.location.href = '';
        } else {
            this.selectedChildStep.update((index) => index! + 1);
        }

        this.#scrollToTop();
    }

    onValidation(): void {
        if (this.isFileNotes || this.isAppComplete) {
            this.onHandleNext();
            return;
        }

        if (this.isProcessFinancialSnapshot()) {
            const isUpdateApplicationProgress =
                ApplicationStepIndex[this.dashboardLastStep] <= ApplicationStepIndex[ApplicationStep.FinancialSnapshot];

            this.finishedStep.emit({ isUpdateApplicationProgress });
            const status = WEBHOOK_STATUS.wealth_health;
            this.triggerWebhook.emit(status);

            this.#scrollToTop();
            return;
        }

        this.formRecord().markAsDirty();
        this.isSubmitted.set(true);
        this.errorMessage.set('');

        const userAnswers = this.#convertFormRecordToUserAnswers();
        if (this.#hasAnyKoQuestion(userAnswers)) {
            this.#openKnockoutDialog();
            return;
        }

        const [warningMessages, errorMessages, flashMessages, flashErrorMessages] = this.#getMessages(userAnswers);

        if (flashErrorMessages.size) {
            this.errorMessages.set(flashErrorMessages);
            return;
        } else {
            this.errorMessages.set(new Map([]));
        }

        if (this.formRecord().invalid) {
            return;
        }

        const isSameMessages = isEqual(flashMessages, this.warningMessages());
        this.#isWarningAbleToNext.set(
            isSameMessages || flashMessages.size === 0 || isNoWarningMessagesExist(flashMessages, this.warningMessages())
        );
        this.warningMessages.set(flashMessages);

        if (errorMessages.length) {
            this.#dialog.open(WarningDialogComponent, {
                autoFocus: false,
                data: { type: 'error', messages: errorMessages },
            });
            this.#isValidateAbleToNext.set(false);
            return;
        } else {
            this.#isValidateAbleToNext.set(true);
        }

        if (warningMessages.length) {
            const dialogRef = this.#dialog.open(WarningDialogComponent, {
                autoFocus: false,
                data: { messages: warningMessages },
            });

            dialogRef
                .afterClosed()
                .pipe(filter((isNext) => isNext))
                .subscribe(() => {
                    this.onSubmit(userAnswers);
                });
        } else {
            if (this.#canNext()) {
                this.warningMessages.set(new Map([]));
                this.#isValidateAbleToNext.set(false);
                this.onSubmit(userAnswers);
            }
        }
    }

    onSubmit(financialUserAnswers: FinancialUserAnswer[]): void {
        this.isLoading.set(true);

        this.#financialSituationService
            .updateFinancialSituation(this.applicationId, financialUserAnswers)
            .subscribe(({ isSuccess, data, message }) => {
                this.#helper.updateStatus(this.applicationId);
                if (!isSuccess) {
                    this.isLoading.set(false);
                    this.errorMessage.set(message);
                    return;
                }

                this.answers = data;

                const targetAnswer = financialUserAnswers[0]?.answers.find(
                    (answer) => answer.financialQuestionId === this.isFinancialQuestionId && answer.answerContent === 'No'
                );

                const isPropertyQuestion = !!targetAnswer;

                if (this.selectedCategory()?.status !== CategoryStatus.Complete) {
                    this.#markSelectedCategoryAsCompleted(isPropertyQuestion);
                } else {
                    this.isLoading.set(false);
                    this.onHandleNext(isPropertyQuestion);
                }
            });
    }

    getSuperannuation(item: FinancialQuestion, applicationUser: unknown): string | null {
        const listenFieldChanges = item.listenFieldChanged.split(LISTEN_FIELD_CHANGED_SPLIT_SEPARATOR);

        if (!listenFieldChanges.length || !item.populateValueFrom) {
            return null;
        }

        const formRecord: FormRecord = <FormRecord>applicationUser;
        const questionId = listenFieldChanges.find(
            (id) => formRecord.controls[id] && formRecord.controls[id].getRawValue().superannuation === 'Included'
        );

        return questionId ? formRecord.controls[questionId].getRawValue().superannuation : null;
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }

    #handlerKOQuestion(answer: FinancialAnswer, question: Question): boolean {
        const answerContent = Number(answer.answerContent);
        if (!answerContent) {
            return false;
        }

        const isPrimaryIncome = question.questionType === QuestionType.PrimaryIncome;

        const value = answer.frequency && isPrimaryIncome ? this.#getValueWithFrequency(answer.frequency, answerContent) : answerContent;

        switch (question.koQuestionType) {
            case KOQuestionType.OutRange: {
                const [from, to] = String(question.koQuestionValue).split('-');
                return !(inRange(value, +from, +to) || value === +to);
            }

            case KOQuestionType.Equal:
                return value === Number(question.koQuestionValue);

            case KOQuestionType.GreaterThan:
                return value > Number(question.koQuestionValue);

            case KOQuestionType.LessThan:
                return value < Number(question.koQuestionValue);

            default:
                return false;
        }
    }

    #convertFormRecordToUserAnswers(): FinancialUserAnswer[] {
        return map(this.formRecord().getRawValue(), (answers, applicationUserId) => {
            const applicationUserFormTemplate = this.formTemplate[applicationUserId];

            return {
                applicationUserId,
                answers: reduce<Partial<{ [key: string]: any }>, FinancialAnswer[]>(
                    answers || [],
                    (result, answer, answerId) => {
                        const templateType = applicationUserFormTemplate.questions[answerId];

                        if (templateType === FinancialTemplateType.GroupQuestion) {
                            return [...result, ...Object.values(answer)];
                        }

                        if (templateType === FinancialTemplateType.ArrayQuestion) {
                            let arrayAnswers: FinancialAnswer[] = [];
                            if (answer.length) {
                                arrayAnswers = answer.reduce(
                                    (previousItem: FinancialAnswer[], item: FinancialAnswer, index: string | number) => {
                                        if (has(item, 'financialQuestionId')) {
                                            return [...previousItem, { ...item, order: index }];
                                        } else {
                                            const updatedFinancialAnswer = Object.values(item).map((financialAnswer: FinancialAnswer) => ({
                                                ...financialAnswer,
                                                order: index,
                                            }));

                                            return [...previousItem, ...updatedFinancialAnswer];
                                        }
                                    },
                                    []
                                );
                            } else {
                                arrayAnswers.push({
                                    financialQuestionId: answerId,
                                    answerContent: '',
                                    order: -1,
                                });
                            }

                            return [...result, ...arrayAnswers];
                        }

                        if (templateType === FinancialTemplateType.NestedGroupQuestion) {
                            const arrayRecordAnswer: FinancialAnswer[] = [];

                            answer.forEach((recordAnswer: { [key: string]: FinancialAnswer }, index: number) => {
                                forOwn(recordAnswer, (financialAnswer) =>
                                    arrayRecordAnswer.push({
                                        ...financialAnswer,
                                        answerContent: financialAnswer.answerContent || '',
                                        order: index,
                                    })
                                );
                            });

                            return [...result, ...arrayRecordAnswer];
                        }

                        return [...result, answer];
                    },
                    []
                ),
            };
        });
    }

    //     #markSelectedCategoryAsCompleted(ispropertyQuestion:boolean = false): void {
    //         const selectedStepIndex = this.selectedStep();
    //         const selectedChildStepIndex = this.selectedChildStep();

    //         if (isNil(selectedStepIndex) || isNil(selectedChildStepIndex)) {
    //             return;
    //         }

    //         const updatedCategoryStatus = getUpdatedCategoryStatus(this.categories, selectedStepIndex, selectedChildStepIndex);

    //         if (!updatedCategoryStatus.length) {
    //             return;
    //         }

    //         this.#cdr.markForCheck();

    //         this.#categoryService.updateStatusCategory(this.applicationId, updatedCategoryStatus).subscribe(({ isSuccess }) => {
    //             if (!isSuccess) {
    //                 this.isLoading.set(false);
    //                 return;
    //             }

    //             if(!ispropertyQuestion){
    //                 this.onHandleNext();
    //             }

    //             this.isLoading.set(false);
    //         });
    //     }

    #markSelectedCategoryAsCompleted(ispropertyQuestion: boolean = false): void {
        const selectedStepIndex = this.selectedStep();
        const selectedChildStepIndex = this.selectedChildStep();

        if (isNil(selectedStepIndex) || isNil(selectedChildStepIndex)) {
            return;
        }

        const updateStatus = (childStepIndex: number, handleNext: boolean = true) => {
            const updatedCategoryStatus = getUpdatedCategoryStatus(this.categories, selectedStepIndex, childStepIndex);

            if (!updatedCategoryStatus.length) {
                return;
            }

            this.#cdr.markForCheck();

            this.#categoryService.updateStatusCategory(this.applicationId, updatedCategoryStatus).subscribe(({ isSuccess }) => {
                if (!isSuccess) {
                    this.isLoading.set(false);
                    return;
                }

                if (handleNext) {
                    this.onHandleNext(ispropertyQuestion);
                }

                this.isLoading.set(false);
            });
        };

        updateStatus(selectedChildStepIndex, !ispropertyQuestion);

        if (ispropertyQuestion) {
            updateStatus(selectedChildStepIndex + 1);
        }
    }

    #createFormTemplate(questions: FinancialQuestion[]) {
        this.applicationUsers.forEach((applicationUser) => {
            this.formTemplate[applicationUser.id] = {
                applicationUserId: applicationUser.id,
                applicationUserName: applicationUser.firstName,
                order: applicationUser.order,
                questions: questions.reduce((previousValue, question) => {
                    let questionType = FinancialTemplateType.SingleQuestion;

                    // if(question.question.questionType === QuestionType.HaveYouEverOwnedPropertyInAustralia) {
                    //     questionType = FinancialTemplateType.CommonQuestion;
                    // }

                    if (question.isMultiAnswer) {
                        questionType = FinancialTemplateType.ArrayQuestion;
                    }

                    if (question.childrenQuestions?.length && !question.isMultiAnswer) {
                        questionType = FinancialTemplateType.GroupQuestion;
                    }

                    if (question.syncNumberOfItemFrom) {
                        questionType = FinancialTemplateType.NestedGroupQuestion;
                    }

                    return {
                        ...previousValue,
                        [question.id]: questionType,
                    };
                }, {}),
            };
        });
    }

    #openKnockoutDialog() {
        this.#dialog.open(KickoutDialogComponent, {
            autoFocus: false,
        });
    }

    #initPopulatedFieldListeners(populatedFields: PopulatedField[], questionsFormRecord: FormRecord): void {
        populatedFields.forEach((populatedField) => {
            const populatedFormControl = getAmountFormById(questionsFormRecord, populatedField.questionId, populatedField.parentQuestionId);

            if (populatedField.listenFieldChanged && populatedFormControl) {
                const listenFieldChanges: {
                    questionId: string;
                    groupQuestionId: string;
                }[] = populatedField.listenFieldChanged.split(LISTEN_FIELD_CHANGED_SPLIT_SEPARATOR).map((listenFieldChange) => {
                    const [questionId, groupQuestionId] = listenFieldChange.split('.').reverse();
                    return { questionId, groupQuestionId };
                });

                const amountFormGroups: (FormGroup<AmountForm> | null)[] = listenFieldChanges.map((it) =>
                    getAmountFormById(questionsFormRecord, it.questionId, it.groupQuestionId)
                );

                const obsValueChanges$ = [...amountFormGroups.filter(Boolean).map((it) => it!.valueChanges)];

                if (obsValueChanges$.length) {
                    merge(...obsValueChanges$)
                        .pipe(debounceTime(300))
                        .subscribe(() => {
                            try {
                                const populatedWhen = getPopulatedValue(populatedField.conditionPopulated, questionsFormRecord);
                                const booleanFunction = Function(`return ${populatedWhen}`);

                                if (booleanFunction()) {
                                    const calculatedFormValue = getPopulatedValue(populatedField.populateValueFrom, questionsFormRecord);

                                    const calculateFunction = Function(`return ${calculatedFormValue}`);

                                    populatedFormControl.controls['answerContent'].setValue(
                                        typeof calculateFunction() === 'number'
                                            ? calculateFunction().toFixed(2)
                                            : calculateFunction().toString()
                                    );
                                } else {
                                    populatedFormControl.controls['answerContent'].setValue('');
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        });
                }
            }
        });
    }

    #initReadonlyFieldListeners(readonlyFields: ReadonlyField[], questionsFormRecord: FormRecord): void {
        readonlyFields.forEach((readonlyField) => {
            const readonlyFormControl = getAmountFormById(questionsFormRecord, readonlyField.questionId, readonlyField.parentQuestionId);

            let conditionRemain = readonlyField.condition;
            const regexResults: RegExpExecArray[] = [];
            let rs;
            while ((rs = RegExp(POPULATED_VALUE_REGEX, 'g').exec(conditionRemain)) !== null) {
                regexResults.push(rs);
                conditionRemain = conditionRemain.slice(rs.index + rs[0].length);
            }

            const resultGroups: { questionId: string; groupQuestionId: string }[] = regexResults.map((it) => ({
                questionId: it.groups ? it.groups['questionId'] : '',
                groupQuestionId: it.groups ? it.groups['groupQuestionId'] : '',
            }));

            const amountFormGroups: (FormGroup<AmountForm> | null)[] = resultGroups.map((it) =>
                getAmountFormById(questionsFormRecord, it.questionId, it.groupQuestionId)
            );

            const obsValueChanges$ = [...amountFormGroups.filter(Boolean).map((it) => it!.valueChanges)];
            if (obsValueChanges$.length) {
                merge(...obsValueChanges$)
                    .pipe(debounceTime(300), startWith(1))
                    .subscribe(() => {
                        try {
                            const readonlyWhen = getPopulatedValue(readonlyField.condition, questionsFormRecord);
                            const booleanFunction = Function(`return ${readonlyWhen}`);

                            if (booleanFunction()) {
                                readonlyFormControl?.controls['answerContent'].disable();
                            } else {
                                readonlyFormControl?.enable({ emitEvent: false });
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    });
            }
        });
    }

    #hasAnyKoQuestion(userAnswers: FinancialUserAnswer[]): boolean {
        return !!userAnswers.find((userAnswer) =>
            userAnswer.answers.find((answer) => {
                const financialQuestion = this.questions.find((item) => item.id === answer.financialQuestionId);

                if (financialQuestion?.question.isKOQuestion && answer.answerContent) {
                    return this.#handlerKOQuestion(answer, financialQuestion.question);
                }

                return false;
            })
        );
    }

    #getMessages(
        currentFinancialUserAnswer: FinancialUserAnswer[]
    ): [string[], string[], Map<string, Map<string, string>>, Map<string, Map<string, string>>] {
        const warningMessages: string[] = [];
        const errorMessages: string[] = [];
        const flashMessages: Map<string, Map<string, string>> = new Map([]);
        const flashErrorMessages: Map<string, Map<string, string>> = new Map([]);

        Object.keys(this.formRecord().controls).forEach((applicationUserId) => {
            const questionsFormRecord = this.formRecord().controls[applicationUserId];
            const financialUserAnswer = currentFinancialUserAnswer.find((item) => item.applicationUserId === applicationUserId);
            const updatedFinancialUserAnswer = this.answers.find((item) => item.applicationUserId === applicationUserId);

            if (!financialUserAnswer) {
                return;
            }

            this.#messageHandler(
                questionsFormRecord.value,
                financialUserAnswer,
                updatedFinancialUserAnswer,
                warningMessages,
                errorMessages,
                flashMessages,
                flashErrorMessages
            );
        });
        return [warningMessages, errorMessages, flashMessages, flashErrorMessages];
    }

    #messageHandler(
        answerList: Partial<{
            [x: string]: any;
        }>,
        financialUserAnswer: FinancialUserAnswer,
        updatedFinancialUserAnswer: FinancialUserAnswer | undefined,
        warningMessages: string[],
        errorMessages: string[],
        flashMessages: Map<string, Map<string, string>>,
        flashErrorMessages: Map<string, Map<string, string>>
    ): void {
        Object.values(answerList).forEach((answer: FinancialAnswer) => {
            if (has(answer, 'financialQuestionId')) {
                const financialQuestion = this.questions.find((item) => item.id === answer.financialQuestionId);
                this.#addMessageToList(
                    financialQuestion,
                    financialUserAnswer,
                    updatedFinancialUserAnswer,
                    answer,
                    warningMessages,
                    errorMessages,
                    flashMessages,
                    flashErrorMessages
                );
            } else {
                this.#messageHandler(
                    answer,
                    financialUserAnswer,
                    updatedFinancialUserAnswer,
                    warningMessages,
                    errorMessages,
                    flashMessages,
                    flashErrorMessages
                );
            }
        });
    }

    #addMessageToList(
        financialQuestion: FinancialQuestion | undefined,
        financialUserAnswer: FinancialUserAnswer,
        updatedFinancialUserAnswer: FinancialUserAnswer | undefined,
        answer: FinancialAnswer,
        warningMessages: string[],
        errorMessages: string[],
        flashMessages: Map<string, Map<string, string>>,
        flashErrorMessages: Map<string, Map<string, string>>
    ): void {
        if (!isEmpty(financialQuestion?.regressionWarnings)) {
            financialQuestion?.regressionWarnings.forEach((regressionWarning) => {
                const formula = getWarningFormula(
                    regressionWarning.formula,
                    financialUserAnswer.answers,
                    updatedFinancialUserAnswer?.answers,
                    answer
                );

                const booleanFunction = Function(`return ${formula}`);
                if (booleanFunction() && !regressionWarning.isValidationMessage) {
                    if (regressionWarning.isErrorMessage && !errorMessages.includes(regressionWarning.warningMessage)) {
                        errorMessages.push(regressionWarning.warningMessage);
                        return;
                    }

                    if (!warningMessages.includes(regressionWarning.warningMessage)) {
                        warningMessages.push(regressionWarning.warningMessage);
                    }
                }

                if (booleanFunction() && regressionWarning.isValidationMessage) {
                    this.#handleAddFlashMessage(flashMessages, financialUserAnswer, financialQuestion.id, regressionWarning.warningMessage);
                }

                if (booleanFunction() && regressionWarning.isValidationMessage && regressionWarning.isErrorMessage) {
                    this.#handleAddFlashMessage(
                        flashErrorMessages,
                        financialUserAnswer,
                        financialQuestion.id,
                        regressionWarning.warningMessage
                    );
                }
            });
        }
    }

    #handleAddFlashMessage(
        messages: Map<string, Map<string, string>>,
        financialUserAnswer: FinancialUserAnswer,
        id: string,
        message: string
    ): void {
        if (!messages.get(financialUserAnswer.applicationUserId)?.size) {
            messages.set(financialUserAnswer.applicationUserId, new Map<string, string>(new Map([])));
        }
        messages.get(financialUserAnswer.applicationUserId)?.set(id, message);
    }

    #getFilteredQuestions(selectedCategory: Category): FinancialQuestion[] {
        return orderBy(
            this.questions.filter(
                (question) =>
                    question.category.id === selectedCategory.id &&
                    (question.displaymode === DisplayMode.All ||
                        question.displaymode === DisplayMode.Merged ||
                        (this.applicationUsers.length === 2
                            ? question.displaymode === DisplayMode.Dual
                            : question.displaymode === DisplayMode.Single))
            ),
            'order'
        );
    }

    #handleCategoryIncomeChange(): void {
        if (this.formRecord()) {
            this.applicationUsers.forEach((appUser) => {
                this.#listenCategoryIncomeChange(appUser, this.selectedQuestions(), <FormRecord>this.formRecord().get(appUser.id));
            });
        }
    }

    #listenCategoryIncomeChange(appUser: ApplicationUser, questions: FinancialQuestion[], questionsForm: FormRecord): void {
        const isIncomeQuestions = questions.every((it) =>
            [CategoryCode.PrimaryIncome, CategoryCode.OtherIncome].includes(it.category.categoryCode)
        );

        if (!isIncomeQuestions) {
            return;
        }

        const incomeControl = getIncomeControl(questionsForm, questions);
        incomeControl?.valueChanges
            .pipe(startWith(incomeControl?.value), takeUntilDestroyed(this.#destroyRef))
            .subscribe((data: FinancialAnswer) => {
                const frequency = data.frequency;
                const isWarning = frequency === 'Monthly' || frequency === 'Weekly';

                if (isWarning) {
                    addWarningMessageRecord(
                        this.warningMessages,
                        appUser.id,
                        data.financialQuestionId,
                        PrimaryIncomeMessage[frequency as 'Monthly' | 'Weekly']
                    );
                } else {
                    deleteWarningMessageRecord(this.warningMessages, appUser.id, data.financialQuestionId);
                }
            });
    }

    public removeWarningMessage(value: { applicationKey: string; id: string }): void {
        if (this.warningMessages().has(value.applicationKey)) {
            this.warningMessages().get(value.applicationKey)!.delete(value.id);
        }
    }

    #getValueWithFrequency(frequency: FinancialFrequency, value: number): number {
        switch (frequency) {
            case 'Weekly':
                return value * 52;
            case 'Monthly':
                return value * 12;
            default:
                return value;
        }
    }
}
