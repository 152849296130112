import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { CommonModule, NgIf, NgSwitch, NgSwitchCase, ViewportScroller } from '@angular/common';
import { catchError, EMPTY, finalize, iif, of, switchMap, take } from 'rxjs';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NavigationComponent } from '@acorn/common-ui';
import { ApplicationService, CategoryService, UserSubscriptionPlanService, WealthHealthService } from '@acorn/data-access';
import {
    AboutYouQuestion,
    AnswerSelection,
    AnswerType,
    ApplicationAboutYouResponse,
    ApplicationStatus,
    ApplicationStep,
    ApplicationSubStep,
    ApplicationUser,
    Category,
    CategoryStatus,
    CustomerUser,
    WealthHealthScore,
    WealthHealthStep,
} from '@acorn/util';
import { ApplicationStepIndex, findLatestStepIndex, getUpdatedCategoryStatus } from '@acorn/feature-application';
import { first, isNil, orderBy } from 'lodash';
import { NestedVerticalStepperComponent, VerticalStepperComponent, WelcomeScreenComponent } from '../ui';
import { WealthBuildingBlocksComponent, WealthHappyScreenComponent, WealthHealthScoreComponent } from './ui';
import { getSelectedCategoryIndex, isWealthScoreStep } from './helpers/wealth-health.helper';
import { TemplateType } from '../feature-about-you/utils';
import { CommonAnswerComponent, CommonAnswerNewComponent, DependantsComponent, LastReviewComponent } from '../feature-about-you/ui';
import { StepStatePipe } from '../feature-risk-tolerance/utils/step-state.pipe';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'acorn-wealth-health',
    templateUrl: 'wealth-health.component.html',
    styleUrls: ['wealth-health.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        CdkStepperModule,
        NavigationComponent,
        WelcomeScreenComponent,
        NgIf,
        VerticalStepperComponent,
        NestedVerticalStepperComponent,
        WealthHealthScoreComponent,
        WealthBuildingBlocksComponent,
        WealthHappyScreenComponent,
        NgSwitch,
        NgSwitchCase,
        CommonAnswerComponent,
        LastReviewComponent,
        StepStatePipe,
        MatDialogModule,
        DependantsComponent,
        CommonAnswerNewComponent,
    ],
})
export class WealthHealthComponent implements OnInit {
    #wealthHealthService = inject(WealthHealthService);
    #viewport = inject(ViewportScroller);
    #applicationService = inject(ApplicationService);
    #userSubscriptionPlanService = inject(UserSubscriptionPlanService);

    @Output() backToSummary = new EventEmitter<void>();
    // new
    @Input({ required: true }) categories: Category[] = [];
    @Input({ required: true }) questions: AboutYouQuestion[] = [];
    @Input({ required: true }) answerSelections: AnswerSelection[] = [];
    @Input({ required: true }) applicationId!: string;
    @Input({ required: true }) applicationUsers!: ApplicationUser[];
    @Input({ required: true }) set applicationAboutYou(value: ApplicationAboutYouResponse[]) {
        this.latestApplicationAboutYou.set(value);
    }

    @Input({ required: true }) latestApplicationStep!: ApplicationStep;
    @Input({ required: true }) dashboardLastStep!: ApplicationStep;
    @Input() isDashboardApp = false;
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;
    @Input() currentUser!: CustomerUser;
    @Output() back = new EventEmitter<void>();
    @Output() finishedStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    public isApplicationEditable = false;

    readonly description = `Understanding your current financial situation is essential, but it's equally <br> important to assess your Wealth Health Score. This score reflects your status in <br> relation to key Wealth Building Blocks. By understanding these building blocks and <br> your Wealth Health Score, you can identify ways to boost your financial health <br> making it easier to achieve your goals.`;

    selectedCategoryIndex = signal<number>(0);
    markAsSeen = signal(false);
    isLoading = signal<boolean>(false);
    wealthHealthScore = signal<WealthHealthScore | null>(null);

    latestApplicationAboutYou = signal<ApplicationAboutYouResponse[]>([]);
    #categoryService = inject(CategoryService);
    #cdr = inject(ChangeDetectorRef);

    protected readonly TemplateType = TemplateType;
    protected readonly CategoryStatus = CategoryStatus;
    public RISK_TOLERANCE_OUTCOME_INDEX = this.categories.length - 1;
    selectedStep = signal<number | undefined>(undefined);
    selectedChildStep = signal<number | undefined>(undefined);

    selectedParentCategory = computed(() => {
        const selectedStepIndex = this.selectedStep();

        if (selectedStepIndex === undefined) {
            return null;
        }

        return this.categories[selectedStepIndex];
    });

    selectedCategory = computed(() => {
        const parentCategory = this.selectedParentCategory();

        if (!parentCategory?.childItems?.length) {
            return null;
        }

        return parentCategory.childItems[this.selectedChildStep()!];
    });

    selectedQuestions = computed(() => {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return [];
        }
        return orderBy(this.questions, 'order').filter((question) => question.aboutYouCategory.id === selectedCategory.id);
    });

    selectedPrimaryQuestion = computed(() => {
        const primaryQuestions = this.selectedQuestions();
        return primaryQuestions[0];
    });

    secondaryPrimaryQuestions = computed(() => {
        const primaryQuestions = this.selectedQuestions();

        const isSuperFunds = primaryQuestions.every((question) => question?.aboutYouCategory?.name === 'Super Funds');

        return isSuperFunds ? primaryQuestions : primaryQuestions[0] ? [primaryQuestions[0]] : [];
    });

    // secondaryQuestions = computed(() => {
    //     const primaryQuestions = this.selectedQuestions();

    //     const isSuperFunds = primaryQuestions.every(
    //         (question) => question?.aboutYouCategory?.name === "Super Funds"
    //     );

    //     return isSuperFunds ? primaryQuestions : primaryQuestions[0] ? [primaryQuestions[0]] : [];
    // });

    selectedTemplateType = computed(() => {
        const selectedQuestions = this.selectedQuestions();

        if (!selectedQuestions?.length) {
            return null;
        }

        const firstQuestion = first(selectedQuestions)!;

        if (firstQuestion.isMultiAnswerQuestion) {
            return TemplateType.MultiAnswerQuestion;
        }

        if (firstQuestion.question.answerType === AnswerType.LastReviewDropDown) {
            return TemplateType.LastReview;
        }

        if (firstQuestion.aboutYouCategory.name === 'Super Funds') {
            return TemplateType.SuperFunds;
        }

        return TemplateType.Default;
    });

    get isFinishedStep() {
        return ApplicationStepIndex[this.latestApplicationStep] > ApplicationStepIndex[ApplicationStep.WealthHealth];
    }

    get currentAppSubStep(): ApplicationSubStep | null {
        return this.currentUser.currentApplication?.currentSubStep || null;
    }

    ngOnInit() {
        if (this.currentUser.currentApplication?.status === 'Active' && this.currentUser.currentSubscription !== null) {
            if (this.currentUser.currentSubscription?.countScheduleTime > 0) {
                this.isApplicationEditable = true;
            }
        }

        const childItem: Category = {
            status: CategoryStatus.Pending,
            name: 'Are you happy',
            parentId: '3a5d09ac-d9f3-4b8c-b36d-ec3643373d13',
            description: '',
            childItems: [],
            order: 17,
            isDualRespondent: false,
            categoryCode: 'AreYouHappy' as any,
            type: 'AboutYou' as any,
            displayOrder: 17,
            id: '9cb428af-47a4-4b5b-8fbd-f7ab02919c74',
            isFirstItem: false,
            isLastItem: true,
            data: null,
        };

        if (this.categories[0]?.childItems && this.isApplicationEditable) {
            this.categories[0]?.childItems.push(childItem);
            this.#setupWealthHealthStepIndex();
        }

        const [stepIndex, childStepIndex] = findLatestStepIndex(this.categories);
        this.selectedStep.set(stepIndex);
        this.selectedChildStep.set(childStepIndex);

        if (stepIndex === 0 && childStepIndex === 0) {
            this.markAsSeen.set(false);
        } else {
            this.markAsSeen.set(true);
            this.selectedStep.set(stepIndex);
            this.selectedChildStep.set(childStepIndex);
        }


        this.#loadWealthHealScoreData();

        // if (isWealthScoreStep(this.selectedCategoryIndex(), this.isApplicationEditable)) {
        //     this.#loadWealthHealScoreData();
        // }
    }

    onCategoryChanged(index: number): void {
        this.selectedCategoryIndex.set(index);

        if (index !== 1) {
            return;
        }

        this.#loadWealthHealScoreData();
    }

    //   onHandleBack(): void {
    //     this.#scrollToTop();

    //     if (this.selectedCategoryIndex() === 0) {
    //       this.back.emit();
    //       return;
    //     }

    //     this.selectedCategoryIndex.update((index) => index - 1);
    //   }

    //   onHandleNext(): void {
    //     if(this.isApplicationEditable && this.selectedCategoryIndex() === 2){
    //       sessionStorage.setItem("welcomeBackEditable", "false");
    //       this.#applicationService
    //         .updateCurrentApplicationStatus(ApplicationStatus.Complete)
    //         .subscribe(() => {
    //           // window.location.reload();
    //         });
    //     }

    //     const wealthStep =   (this.selectedCategoryIndex(), this.isApplicationEditable)
    //       ? WealthHealthStep.WealthScore
    //       : WealthHealthStep.WealthBuildingBlock;

    //     this.#userSubscriptionPlanService.userSubscription$
    //       .pipe(
    //         take(1),
    //         switchMap((userSubscription) =>
    //           iif(
    //             () => this.isFinishedStep,
    //             of(Boolean(!userSubscription)),
    //             this.#applicationService
    //               .updateCurrentProgress(ApplicationStep.WealthHealth, wealthStep)
    //               .pipe(switchMap(() => of(true)))
    //           )
    //         )
    //       )
    //       .subscribe({
    //         next: (isUpdateProgress) => {
    //           this.#scrollToTop();
    //           this.#handleWealthHealthNextStep(isUpdateProgress);
    //         },
    //       });

    //   }

    #handleWealthHealthNextStep(isUpdateApplicationProgress: boolean): void {
        if (this.isApplicationEditable) {
            isUpdateApplicationProgress = true;
        }
        this.isApplicationEditable = false;
        this.categories[0]?.childItems?.pop();
        localStorage.setItem('financialProjectionProgress', 'true');

        window.dispatchEvent(new CustomEvent('yourEventType', { detail: true }));
        this.#cdr.detectChanges();

        if (isWealthScoreStep(this.selectedChildStep(), isUpdateApplicationProgress)) {
            this.finishedStep.emit({ isUpdateApplicationProgress });
        } else {
            window.location.href = '';
        }
    }

    #loadWealthHealScoreData(): void {
        this.isLoading.set(true);
        this.#wealthHealthService
            .getScore()
            .pipe(
                catchError(() => EMPTY),
                finalize(() => this.isLoading.set(false))
            )
            .subscribe(({ data, isSuccess }) => {
                if (!isSuccess || !data) {
                    return;
                }

                this.wealthHealthScore.set(data);
            });
    }

    #setupWealthHealthStepIndex(): void {
        const isFinishSubStep =
            Boolean(this.currentAppSubStep) && (Object.values(WealthHealthStep) as string[]).includes(this.currentAppSubStep!.toString());

        this.markAsSeen.set(this.isFinishedStep || isFinishSubStep);

        if (this.markAsSeen()) {
            this.selectedChildStep.set(getSelectedCategoryIndex(this.isFinishedStep, this.currentAppSubStep, this.isApplicationEditable));
        }
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }

    onInitializeStep(): void {
        this.markAsSeen.set(true);
        this.selectedStep.set(0);
        this.selectedChildStep.set(0);
    }

    onSelectStep(index: number): void {
        const updatedCategory = this.categories[index];

        if (!updatedCategory) {
            return;
        }

        if (updatedCategory.status !== CategoryStatus.Complete) {
            this.selectedStep.set(index);
            return;
        }

        this.selectedStep.set(index);
        this.selectedChildStep.set(0);
    }

    onSelectChildStep(index: number): void {
        this.selectedChildStep.set(index);
        this.#scrollToTop();
    }

    onUpdateApplicationAboutYou(updatedApplicationAboutYou: ApplicationAboutYouResponse[]): void {
        this.latestApplicationAboutYou.set(updatedApplicationAboutYou);
    }

    onBeforeHandleNext(): void {
        if (this.isFileNotes || this.isAppComplete) {
            this.onHandleNext();
            return;
        }

        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return;
        }

        if (selectedCategory.categoryCode === 'WealthBuildingBlocks') {
            this.#loadWealthHealScoreData();
        }

        if (selectedCategory.status !== CategoryStatus.Complete) {
            this.#markSelectedCategoryAsComplete();
        } else {
            this.onHandleNext();
        }
    }

    #markSelectedCategoryAsComplete(): void {
        const selectedStepIndex = this.selectedStep();
        const selectedChildStepIndex = this.selectedChildStep();

        if (isNil(selectedStepIndex) || isNil(selectedChildStepIndex)) {
            return;
        }

        const updatedCategoryStatus = getUpdatedCategoryStatus(this.categories, selectedStepIndex, selectedChildStepIndex);

        if (!updatedCategoryStatus.length) {
            return;
        }

        this.#cdr.markForCheck();

        this.#categoryService.updateStatusCategory(this.applicationId, updatedCategoryStatus).subscribe(({ isSuccess }) => {
            if (!isSuccess) {
                return;
            }

            this.onHandleNext();
        });
    }

    onHandleBack(): void {
        const selectedCategory = this.selectedCategory();
        const selectedParentCategory = this.selectedParentCategory();

        if (!selectedCategory || !selectedParentCategory) {
            return;
        }

        if (selectedCategory.isFirstItem && selectedParentCategory.isFirstItem) {
            this.back.emit();
            return;
        }

        if (selectedCategory.isFirstItem) {
            const currentStepIndex = this.selectedStep()!;
            this.selectedStep.set(currentStepIndex - 1);

            const childStepIndex = this.categories[currentStepIndex - 1].childItems?.length!;
            this.selectedChildStep.set(childStepIndex - 1);

            return;
        }

        this.selectedChildStep.update((index) => index! - 1);
        this.#scrollToTop();
    }

    onHandleNext(): void {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return;
        }

        if (this.isApplicationEditable && this.selectedChildStep() === 9) {
            this.selectedChildStep.set(10);
            return;
        }

        if (this.selectedCategory()?.isLastItem) {
            if (this.selectedParentCategory()?.isLastItem) {
                if (this.selectedChildStep() === 9) {
                    const isUpdateApplicationProgress =
                        ApplicationStepIndex[this.dashboardLastStep] <= ApplicationStepIndex[ApplicationStep.FinancialProjection];

                    this.finishedStep.emit({ isUpdateApplicationProgress });
                }
            } else {
                this.selectedStep.update((step) => step! + 1);
                this.selectedChildStep.set(0);
            }
        } else {
            this.selectedChildStep.update((index) => index! + 1);
        }

        this.markAsSeen.set(true);
    }

    onNext() {
        if (this.isApplicationEditable && this.selectedChildStep() === 10) {
            sessionStorage.setItem('welcomeBackEditable', 'false');
            this.#applicationService.updateCurrentApplicationStatus(ApplicationStatus.Complete).subscribe(() => {
                // window.location.reload();
            });

            this.#applicationService.markAsApplicationCompleted(this.currentUser.currentApplication?.id).subscribe(() => {});
        }

        const wealthStep = isWealthScoreStep(this.selectedChildStep(), this.isApplicationEditable)
            ? WealthHealthStep.WealthScore
            : WealthHealthStep.WealthBuildingBlock;

        this.#userSubscriptionPlanService.userSubscription$
            .pipe(
                take(1),
                switchMap((userSubscription) =>
                    iif(
                        () => this.isFinishedStep,
                        of(Boolean(!userSubscription)),
                        this.#applicationService
                            .updateCurrentProgress(ApplicationStep.WealthHealth, wealthStep)
                            .pipe(switchMap(() => of(true)))
                    )
                )
            )
            .subscribe({
                next: (isUpdateProgress) => {
                    this.#scrollToTop();
                    this.#handleWealthHealthNextStep(isUpdateProgress);
                },
            });
    }

    onBack() {
        this.#scrollToTop();
        this.selectedChildStep.update((index: any) => index - 1);
    }
}
