import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    inject,
    signal,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Chart, ChartDataset } from 'chart.js/auto';
import { NavigationComponent } from '@acorn/common-ui';
import { MatDialog } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { GoalPopupComponent } from './ui/goal-popup/goal-popup.component';
import { KeyAssumptionsPopupComponent } from './ui/key-assumptions-popup/key-assumptions-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { FinancialProjectionService } from './data-access-financial-projection/financial-projection.service';
import { ApplicationStep } from '@acorn/util';
import { ApplicationService, UserSubscriptionPlanService } from '@acorn/data-access';
import { ApplicationStepIndex } from '../util';
import { FinancialSnapshotType } from '../feature-financial-situation/utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ChartLayoutComponent } from '../chart-layout/chart-layout.component';
import { ErrorPopupComponent } from './ui';

export interface FinancialData {
    name: string;
    measure: string;
    calculationTypeCode: string;
    financialYear: number;
    itemId: string;
    value: number;
    strategyId?: string;
}

export interface AgeData {
    age: number;
    data: { [key: string]: number };
}

@Component({
    selector: 'acorn-feature-financial-projection',
    standalone: true,
    imports: [
        CommonModule,
        NavigationComponent,
        MatCardModule,
        GoalPopupComponent,
        KeyAssumptionsPopupComponent,
        MatButtonModule,
        MatExpansionModule,
        MatDividerModule,
        ChartLayoutComponent,
    ],
    providers: [FinancialProjectionService, UserSubscriptionPlanService, ApplicationService],
    templateUrl: './feature-financial-projection.component.html',
    styleUrls: ['./feature-financial-projection.component.scss'],
})
export class FeatureFinancialProjectionComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('barCanvas') private barCanvas!: ElementRef;

    @Output() back = new EventEmitter<void>();

    @Input() isManualFlow: any;
    @Input() applicationId: any;
    @Input() isReCalculation: boolean = false;
    @Input() isFileNotes: boolean = false;

    #viewport = inject(ViewportScroller);
    protected readonly FinancialSnapshotType = FinancialSnapshotType;

    markAsSeen = signal(false);
    barChart: any;
    isMobile: boolean = false;
    #dialog = inject(MatDialog);
    #financialProjectionService = inject(FinancialProjectionService);

    isSubmitted = signal<boolean>(false);

    cd = inject(ChangeDetectorRef);
    @Input({ required: true }) latestApplicationStep!: ApplicationStep;

    @Output() finishedStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    @Output() adjustStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    @Output() financialSituation = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    @Output() isCalculationStopped = new EventEmitter<boolean>();

    @Output() movetoPrevious = new EventEmitter();

    errorMessage = signal<string>('');

    isLandscape: boolean = false;
    expanded: boolean = false;
    chartContainer: any;
    originalWidth: any;
    processedData: AgeData[] = [];
    datasets: ChartDataset<'bar' | 'line', number[]>[] = [];
    chartLabels: string[] = [];
    #ngxLoader = inject(NgxUiLoaderService);

    public showDeafult: boolean = true;
    public showReal: boolean = false;
    public isLoading: boolean = false;
    public barThickness: number = 20;
    public defaultLabels: any = ['50', '55', '60', '65', '70', '75', '80', '85', '90'];
    public defaultDatasets: any = [
        {
            type: 'line',
            label: 'Net Asset Position',
            data: [5000000, 9000000, 12000000, 15000000, 14000000, 11000000, 8000000, 5000000, 2000000],
            borderColor: 'black',
            backgroundColor: 'black',
            borderWidth: 3,
            fill: false,
            pointStyle: 'line',
            pointRadius: 0,
        },
        {
            type: 'bar',
            label: 'Other Loans',
            data: [3000000, 5000000, 7000000, 9000000, 10000000, 9000000, 8000000, 7000000, 6000000],
            backgroundColor: '#f79f64',
            barThickness: this.barThickness,
            borderColor: 'black',
            borderWidth: 0,
            stack: 'Stack 0',
            pointStyle: 'circle',
        },
        {
            type: 'bar',
            label: 'Superannuation',
            data: [2000000, 3000000, 5000000, 6000000, 5000000, 4000000, 3000000, 2000000, 1000000],
            backgroundColor: '#c0da53',
            barThickness: this.barThickness,
            borderColor: 'black',
            borderWidth: 0,
            stack: 'Stack 0',
            pointStyle: 'circle',
        },
        {
            type: 'bar',
            label: 'Investment Property Portfolio',
            data: [4000000, 6000000, 8000000, 10000000, 10000000, 9000000, 8000000, 7000000, 6000000],
            backgroundColor: '#3a8baf',
            barThickness: this.barThickness,
            borderColor: 'black',
            borderWidth: 0,
            stack: 'Stack 0',
            pointStyle: 'circle',
        },
        {
            type: 'bar',
            label: 'Education Fund',
            data: [-5000000, -7000000, -9000000, -11000000, -12000000, -13000000, -14000000, -15000000, -16000000],
            backgroundColor: '#de99b7',
            barThickness: this.barThickness,
            borderColor: 'black',
            borderWidth: 0,
            stack: 'Stack 0',
            pointStyle: 'circle',
        },
        {
            type: 'bar',
            label: 'Cash (incl.Emergency & Funeral Funds)',
            data: [1000000, 2000000, 3000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000],
            backgroundColor: '#264b6a',
            barThickness: this.barThickness,
            borderColor: 'black',
            borderWidth: 0,
            stack: 'Stack 0',
            pointStyle: 'circle',
        },
    ];

    calculationInterval: any;
    id: any;
    modal: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.barThickness = window.innerWidth <= 768 ? 10 : 20;
        if (this.barChart) {
            this.updateLegendPosition();
            this.barChart.update();
        }
    }

    constructor(private elementRef: ElementRef, private applicationSvc: ApplicationService) {}

    ngAfterViewInit(): void {
        this.createChart(this.defaultLabels, this.defaultDatasets);
        if (this.processedData && this.processedData.length > 0) {
            this.isCalculationStopped.emit(true);
        } else {
            this.getApplicationCalculations();
        }
    }

    detectOrientation() {
        this.isLandscape = window.innerWidth > window.innerHeight;
    }

    ngOnInit(): void {
        this.createChart(this.defaultLabels, this.defaultDatasets);

        this.showDeafult = true;
        this.isMobile = window.innerWidth < 768;
        this.detectOrientation();
        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 768;
            this.detectOrientation();
        });

        // localStorage.removeItem('financialProjectionProgress');
    }

    public GetChartData() {
        this.#ngxLoader.start();
        this.#financialProjectionService.getFinancialProjection(this.applicationId).subscribe((res: any) => {
            this.#ngxLoader.stop();
            const rawData: FinancialData[] = res.data;
            this.processedData = this.processFinancialData(rawData);

            if (this.processedData.length === 0) {
                this.isCalculationStopped.emit(true);
                this.showDeafult = false;
                if (this.barChart) {
                    this.barChart.destroy();
                }
            }

            if (this.processedData) {
                this.isLoading = true;
                this.generateBarChart();
                this.modal?.close();
                this.isCalculationStopped.emit(true);
            }

            this.cd.markForCheck();
        });
    }

    public getApplicationCalculations() {
        this.showDeafult = true;

        this.#financialProjectionService.getApplicationsCalculations(this.applicationId).subscribe(
            (res: any) => {
                this.id = res?.data?.id;
                if (res.data.statusQuoStatus === 'Completed') {
                    clearInterval(this.calculationInterval);
                    this.GetChartData();
                    return;
                } else {
                    if (this.id) {
                        this.reCalculate();
                    }
                }
            },
            (err: any) => {
                this.isCalculationStopped.emit(true);
                this.openPopup();
            }
        );
    }

    public reCalculate() {
        this.#financialProjectionService.reCalculate(this.applicationId, this.id).subscribe(
            (res: any) => {
                if (res?.data?.statusQuoStatus === 'Completed') {
                    clearInterval(this.calculationInterval);
                    this.GetChartData();
                } else if (res?.data?.status === 'Error') {
                    this.isCalculationStopped.emit(true);
                    this.openPopup();
                    clearInterval(this.calculationInterval);
                } else {
                    clearInterval(this.calculationInterval);
                    if (res?.data?.status !== 'Error') {
                        this.calculationInterval = setInterval(() => this.reCalculate(), 5000);
                    }
                }
            },
            (err: any) => {
                this.isCalculationStopped.emit(true);
                this.openPopup();
            }
        );
    }

    openPopup() {
        this.modal = this.#dialog.open(ErrorPopupComponent, {
            width: '500px',
            height: '250px',
            disableClose: true,
            hasBackdrop: true,
        });

        this.modal.componentInstance.tryAgain.subscribe(() => {
            this.modal.close();
            this.isCalculationStopped.emit(false);
            this.getApplicationCalculations();
        });

        this.modal.componentInstance.close.subscribe(() => {
            this.#dialog.closeAll();
            this.movetoPrevious.emit();
        });
    }

    ngOnDestroy(): void {
        if (this.calculationInterval) {
            clearInterval(this.calculationInterval);
        }
    }

    processFinancialData(data: FinancialData[]): AgeData[] {
        const ageDataMap: Map<number, AgeData> = new Map();
        const filteredData = data.filter((item) => item.name !== 'Check' && item.name !== 'Present Value of Net Assets');
        filteredData.forEach((item) => {
            const year = item.financialYear;
            let ageData = ageDataMap.get(year);

            if (!ageData) {
                ageData = { age: 0, data: {} };
                ageDataMap.set(year, ageData);
            }

            if (item.name === 'Age') {
                ageData.age = item.value;
            } else {
                ageData.data[item.name] = Math.round(item.value);
            }
        });

        return Array.from(ageDataMap.values());
    }

    viewFullscreen() {
        this.chartContainer = this.elementRef.nativeElement.querySelector('#chart-container');
        const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (isiOS) {
            let modal = this.#dialog.open(ChartLayoutComponent, {
                width: '100%',
                height: '100vh',
                disableClose: true,
                hasBackdrop: true,
                panelClass: 'financial-dialog-container',
                data: {
                    chartLabels: this.chartLabels,
                    datasets: this.datasets,
                },
            });
        } else {
            const chartContainer = this.elementRef.nativeElement.querySelector('#chart-container');

            if (chartContainer) {
                this.originalWidth = chartContainer.offsetWidth;

                this.expanded = true;

                if (chartContainer.requestFullscreen) {
                    chartContainer.requestFullscreen();
                    this.makeLandscape();
                } else if (chartContainer.mozRequestFullScreen) {
                    chartContainer.mozRequestFullScreen();
                    this.makeLandscape();
                } else if (chartContainer.webkitRequestFullscreen) {
                    chartContainer.webkitRequestFullscreen();
                    this.makeLandscape();
                }
            }
        }
    }

    onClose() {
        this.expanded = false;

        if (this.chartContainer && this.originalWidth) {
            this.chartContainer.style.width = `${this.originalWidth}px`;
        }

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        }
    }

    makeLandscape() {
        if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock('landscape');
        }
    }

    private generateBarChart() {
        if (this.processedData && this.processedData.length > 0) {
            this.chartLabels = this.processedData.map((entry) => entry.age.toString());
            this.datasets = [
                {
                    type: 'line',
                    label: 'Net Asset Position',
                    data: this.processedData.map((entry) => entry.data['Net Asset Position']),
                    borderColor: 'black',
                    backgroundColor: '323E48',
                    borderWidth: 3,
                    fill: false,
                    pointStyle: 'line',
                    pointRadius: 0,
                },
            ];

            const financialCategories = Object.keys(this.processedData[0].data).filter((key) => key !== 'Net Asset Position');

            const categoryColors = {
                'Cash (incl. Emergency & Funeral Funds)': '#264b6a',
                'Education Fund': '#de99b7',
                'Owner-Occupied Property': '#da627d',
                'Investment Property Portfolio': '#3a8baf',
                'Other Assets (incl. Motor Vehicles)': '#2ec4b6',
                'Superannuation': '#c0da53',
                'Home Loan': '#84a98c',
                'Investment Loans': '#a167a5',
                'Other Loans': '#f79f64',
                'Shares & Other Investments': '#ff0000',
            };

            financialCategories.forEach((category, index) => {
                this.datasets.push({
                    type: 'bar',
                    label: category,
                    data: this.processedData.map((entry) => entry.data[category]),
                    border: 'none',
                    backgroundColor: categoryColors[category as keyof typeof categoryColors] || '#000000', // Assert type of category
                    fill: false,
                    pointStyle: 'circle',
                    pointRadius: 0,
                } as ChartDataset<'bar', number[]>);
            });

            this.createChart(this.chartLabels, this.datasets);
        }
    }

    public createChart(chartLables: any, datasets: any) {
        if (this.barChart) {
            this.barChart.destroy();
        }

        const legendOrder = [
            'Other Loans',
            'Investment Loans',
            'Home Loan',
            'Superannuation',
            'Other Assets (incl. Motor Vehicles)',
            'Shares & Other Investments',
            'Investment Property Portfolio',
            'Owner-Occupied Property',
            'Education Fund',
            'Cash (incl. Emergency & Funeral Funds)',
            'Net Asset Position',
        ];

        if (this.barCanvas) {
            this.isLoading = false;
            this.barChart = new Chart(this.barCanvas.nativeElement, {
                type: 'bar',
                data: {
                    labels: chartLables,
                    datasets: datasets,
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'right',
                            maxWidth: 350,
                            labels: {
                                sort: (a, b) => {
                                    return legendOrder.indexOf(a.text) - legendOrder.indexOf(b.text);
                                },
                                padding: 20,
                                boxWidth: 10,
                                usePointStyle: true,
                                font: {
                                    family: 'Gotham',
                                },
                            },
                        },
                        tooltip: {
                            callbacks: {
                                title: function (tooltipItems) {
                                    return `Age: ${tooltipItems[0].label}`;
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Age of youngest respondent',
                                font: {
                                    size: 14,
                                    weight: 'bold',
                                },
                            },
                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                            ticks: {
                                callback: function (value) {
                                    return Number(value) / 1000000 + 'M';
                                },
                            },
                            title: {
                                display: true,
                                text: '$AUD',
                                font: {
                                    size: 14,
                                    weight: 'bold',
                                },
                            },
                        },
                    },
                },
            });
        }
        this.updateLegendPosition();
    }

    private updateLegendPosition(): void {
        const legendPosition = window.innerWidth <= 768 ? 'bottom' : 'right';
        const fontSize = window.innerWidth <= 768 ? 8 : 12;
        if (this.barChart?.options.scales && this.barChart?.options.scales.x && this.barChart?.options.scales.x.title) {
            this.barChart.options.scales.x.title.font = {
                size: fontSize,
                weight: '400',
                family: 'Gotham',
            };
        }

        if (this.barChart && this.barChart?.options && this.barChart?.options.plugins && this.barChart?.options.plugins.legend) {
            this.barChart.options.plugins.legend.position = legendPosition;
        }

        if (this.barChart?.options.scales && this.barChart?.options.scales.y && this.barChart?.options.scales.y.title) {
            this.barChart.options.scales.y.title.font = {
                size: fontSize,
                weight: '400',
                family: 'Gotham',
            };
        }

        if (this.barChart?.options.plugins.legend.labels) {
            this.barChart.options.plugins.legend.labels.font = {
                size: fontSize,
                weight: '400',
            };
        }

        this.barChart?.update();
    }

    public openAssumptions() {
        this.#dialog.open(KeyAssumptionsPopupComponent, {
            width: '1200px',
            disableClose: true,
            hasBackdrop: true,
            data: {
                applicationId: this.applicationId,
            },
        });
    }

    moveToGoalRoundVerify(): void {
        let isUpdateApplicationProgress = false;
        this.adjustStep.emit({ isUpdateApplicationProgress });
        this.#scrollToTop();
    }

    moveToFinancialSituation() {
        let isUpdateApplicationProgress = false;
        this.financialSituation.emit({ isUpdateApplicationProgress });
        this.#scrollToTop();
    }

    onHandleNext(): void {
        //   const isUpdateApplicationProgress =
        //   ApplicationStepIndex[this.latestApplicationStep] <= ApplicationStepIndex[ApplicationStep.WealthCoach];

        //     this.applicationSvc.markAsApplicationCompleted(this.applicationId).subscribe(
        //         (res: any) => {
        this.finishedStep.emit({ isUpdateApplicationProgress: true });
        //         this.#scrollToTop();
        //     },
        //     (err: any) => {
        //         this.finishedStep.emit({ isUpdateApplicationProgress: true });
        //         this.#scrollToTop();
        //     }
        // );
    }

    onHandleBack(): void {
        this.#scrollToTop();
        this.back.emit();
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }
}
