import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationStrategyDocumentModelDto, assetMapping, PDFDownloadType } from '../../utils/wealth-helper';
import { StrategyServiceHelper } from '../../../helpers/strategy-service.helper';
import { environment } from '@env';
import { take, map, catchError, of } from 'rxjs';
import { WealthCoachPopupComponent } from '../../../ui/wealthCoach-popup/wealth-coach-popup.component';

@Component({
    selector: 'acorn-popup',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    providers:[StrategyServiceHelper]
})
export class WealthStrategyOptionPopupComponent {
    #dialogRef = inject(MatDialogRef<WealthStrategyOptionPopupComponent>);
    dialogData = inject(MAT_DIALOG_DATA);
    strategySvc = inject(StrategyServiceHelper);
    loading: boolean = false;
    #dialog = inject(MatDialog);

    onClose() {
        this.#dialogRef.close();
    }

    getImagePath(bb: string): string {
        const match = assetMapping.find((item) => item.title === bb);
        return match ? match.imagePath : '';
    }

    formatStrategyOption(option: string): string {
        return option.replace(/(\d+)/, ' $1');
    }

    public getGoals(goals: Array<any>, value: boolean): Array<any> {
        return goals.filter((e) => e.isLikely === value);
        // return goals.sort((a, b) => b.isLikely - a.isLikely);
    }

    downloadChosenSOA() {
        // this.openWealthCoachPopup(false ,true).subscribe((isApproved) => {
        //     if(isApproved){
                this.loading = true;
                const url = `${environment.pdfUrl}wealth-strategy/${this.dialogData?.strategy?.applicationId}`;

                const data: ApplicationStrategyDocumentModelDto = {
                    applicationId: this.dialogData?.strategy?.applicationId,
                    blobUrl: '',
                    pdfDownloadTypeId: PDFDownloadType.ChosenStrategy,
                    documentUrl: url,
                };

                this.strategySvc.download(data, 'Chosen SOA', (success: boolean) => {
                    this.loading = false;
                });
        //     }
        // });
    }

    public openWealthCoachPopup(isShowPreviousSOA: boolean ,isShowCallofDate:boolean) {
        const dialogRef = this.#dialog.open(WealthCoachPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            width:'500px',
            data: {
                applicationId: this.dialogData?.strategy?.applicationId,
                isShowPreviousSOA:isShowPreviousSOA ,
                isShowCallofDate:isShowCallofDate
            },
        });

        return dialogRef.componentInstance.added.pipe(
            take(1),
            map(() => true),
            catchError(() => of(false))
        );
    }
}
