import { NavigationComponent } from '@acorn/common-ui';
import { ApplicationService, BaseContentfulService, UserService } from '@acorn/data-access';
import {
    ApplicationStatus,
    CustomerUser,
    MembershipPlanEntry,
    MembershipPlanEntryResponse,
    MembershipType,
    Subscription,
    WealthCoachStep,
} from '@acorn/util';
import { CommonModule, ViewportScroller } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Injector,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { finalize, from, map, of, ReplaySubject } from 'rxjs';

import { CompletedSuccessfullyProfileComponent, WealthCoachScheduleComponent } from './ui';
import { ReturningCustomersComponent } from './ui/returning-customers';
import { MemberShipMapToIdContentful } from './utils/member-ship-map-to-id-contentful';
import { mappingMembershipPlan } from './utils/membership-plan.mapper';

@Component({
    selector: 'acorn-wealth-coach',
    templateUrl: 'wealth-coach.component.html',
    styleUrls: ['wealth-coach.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NavigationComponent,
        CompletedSuccessfullyProfileComponent,
        ReturningCustomersComponent,
        CommonModule,
        MatProgressSpinnerModule,
        WealthCoachScheduleComponent,
    ],
})
export class WealthCoachComponent implements OnInit {
    readonly #innerCalendarLink$ = new ReplaySubject<string | null>(1);

    readonly wealthCoachStep = WealthCoachStep;
    readonly calendarLink$ = this.#innerCalendarLink$.asObservable();

    #applicationService = inject(ApplicationService);
    #userService = inject(UserService);
    #contentFullService = inject(BaseContentfulService);
    #viewport = inject(ViewportScroller);

    userSubscription!: Subscription | null;

    isProfileComplete = signal<boolean>(false);
    isBooked = false;
    isScheduleComplete = signal<boolean>(false);
    membershipPlanEntry!: Signal<MembershipPlanEntry>;
    currentStep = signal<WealthCoachStep | null>(null);
    isLoading = signal<boolean>(false);

    welcomeBackEditable = false;

    isShowYouAreSetPage: Signal<boolean> = computed(
        () => this.isProfileComplete() && (this.currentStep() === null || this.isScheduleComplete())
    );

    @Input() currentUser!: CustomerUser;
    @Input() isDashboardApp = false;
    @Input() calendarLink: string | null = null;
    @Input() isFileNotes = false;

    @Output() back = new EventEmitter<void>();
    @Output() backToGoal = new EventEmitter<void>();

    @ViewChild('calendarElem', { read: ElementRef })
    calendarElem!: ElementRef<HTMLDivElement>;

    get membershipCode(): MembershipType | undefined {
        return this.userSubscription?.subscriptionPlan.membershipCode;
    }

    get countScheduleTime(): number {
        return this.userSubscription?.countScheduleTime || 0;
    }
    cd = inject(ChangeDetectorRef);

    constructor(private injector: Injector) {}

    ngOnInit() {
        const storedProfileComplete = sessionStorage.getItem('isProfileComplete');
        const storedScheduleComplete = sessionStorage.getItem('isScheduleComplete');

        if (storedProfileComplete === 'true') {
            this.isProfileComplete.set(true);
        } else {
            this.isProfileComplete.set(false);
        }

        if (storedScheduleComplete === 'true') {
            this.isScheduleComplete.set(true);
        } else {
            this.isScheduleComplete.set(false);
        }

        this.userSubscription = this.currentUser.currentSubscription;

        if (
            sessionStorage.getItem('welcomeBackEditable') != null ||
            this.currentUser.currentApplication?.status === ApplicationStatus.Complete
        ) {
            this.welcomeBackEditable = false;
            sessionStorage.removeItem('welcomeBackEditable');
        } else {
            this.welcomeBackEditable = true;
        }

        if (this.membershipCode) {
            this.#setupMembershipPlanEntry(this.membershipCode);
            this.#setupCurrentWealthCoachStep();
        }
    }

    onBackToGoalStep(): void {
        this.backToGoal.emit();
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent) {
        if (event.data.event && event.data.event.indexOf('calendly') === 0) {
            if (event.data.event === 'calendly.event_scheduled') {
                this.#applicationService.updateCurrentApplicationStatus(ApplicationStatus.Complete).subscribe(() => {

                    this.#applicationService.markAsApplicationCompleted(this.currentUser.currentApplication?.id).subscribe(
                        (res: any) => {
                            this.#scrollToTop();
                            this.isBooked = true;
                            this.isProfileComplete.set(true);
                            this.isScheduleComplete.set(true);
                            localStorage.setItem('financialProjectionProgress', 'true');
                            sessionStorage.setItem('isProfileComplete', 'true');
                            sessionStorage.setItem('isScheduleComplete', 'true');
                            window.dispatchEvent(new CustomEvent('yourEventType', { detail: true }));

                            this.cd.detectChanges();
                        },
                        (err: any) => {}
                    );

                });
            }
        }
    }

    #getCalendarLink(): void {
        const getCalendarLink$ = this.isDashboardApp ? of({ data: this.calendarLink }) : this.#userService.getWealthCoachCalendarLink();

        getCalendarLink$.subscribe({
            next: (res) => {
                this.#innerCalendarLink$.next(res.data);
            },
        });
    }

    #setupMembershipPlanEntry(membershipCode: MembershipType): void {
        this.isLoading.set(true);
        this.membershipPlanEntry = toSignal(
            from(this.#contentFullService.getEntry<MembershipPlanEntryResponse>(MemberShipMapToIdContentful.get(membershipCode)!)).pipe(
                map((response) => mappingMembershipPlan(response)),
                finalize(() => this.isLoading.set(false))
            ),
            { initialValue: <MembershipPlanEntry>{}, injector: this.injector }
        );
    }

    #setupCurrentWealthCoachStep(): void {
        const currentApplication = this.currentUser.currentApplication;
        this.isProfileComplete.set(currentApplication?.status === ApplicationStatus.Complete);

        const currentStep = <WealthCoachStep | null>currentApplication?.currentSubStep;

        this.currentStep.set(this.#getCurrentStep(currentStep));
        if (this.currentStep() === WealthCoachStep.Schedule) {
            this.#getCalendarLink();
        }
    }

    #getCurrentStep(currentStep: WealthCoachStep | null): WealthCoachStep | null {
        if (!this.isProfileComplete() && !currentStep) {
            return this.countScheduleTime > 0 ? WealthCoachStep.WelcomeBackEditable : WealthCoachStep.Schedule;
        }

        return currentStep;
    }

    onHandleBack() {
        this.isProfileComplete.set(true);
        this.isScheduleComplete.set(true);
        this.#scrollToTop();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        // //this.back.emit();
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }
}
