import { ApplicationStep, FinancialCategoryType } from "@acorn/util";

export const StepDetailData: StepDetail[] = [
    {
        status: 'in-process',
        title: 'About You',
        stepTitle: ApplicationStep.AboutYou,
        description: "We'd love to learn more about you! Provide your information to get started.",
        buttonText: 'Start',
        percentage: 0,
        minute:3,
        imagePath:'about-you.svg',
        children: []
    },
    {
        status: 'in-complete',
        title: 'Your Goals',
        stepTitle: ApplicationStep.Goal,
        description: "Choose your financial goals and figure out what's most important to you here.",
        buttonText: 'Start',
        percentage: 0,
        minute:10,
        imagePath:'your-goals.svg',
        children: []
    },
    {
        status: 'in-complete',
        title: 'Financial Snapshot',
        stepTitle: ApplicationStep.FinancialSnapshot,
        description: 'Complete a snapshot of your current financial position here.',
        buttonText: 'Start',
        percentage: 0,
        minute:40,
        imagePath:'financial-snapshot.svg',
        children: [
            {
                status: 'in-complete',
                title: 'Income',
                stepTitle: FinancialCategoryType.Income,
                description: '',
                buttonText: 'Start',
                percentage: 0,
                minute:10,
                children: []
            },
            {
                status: 'in-complete',
                title: 'Expenses',
                stepTitle: FinancialCategoryType.Expenses,
                description: '',
                buttonText: 'Start',
                percentage: 0,
                minute:10,
                children: []
            },
            {
                status: 'in-complete',
                title: 'Assets',
                stepTitle: FinancialCategoryType.Assets,
                description: '',
                buttonText: 'Start',
                percentage: 0,
                minute:10,
                children: []
            },
            {
                status: 'in-complete',
                title: 'Liabilities',
                stepTitle: FinancialCategoryType.Liabilities,
                description: '',
                buttonText: 'Start',
                percentage: 0,
                minute:10,
                children: []
            }
        ]
    },
    {
        status: 'in-complete',
        title: 'Risk Tolerance',
        stepTitle: ApplicationStep.RiskTolerance,
        description: "Discover your risk tolerance to understand how much risk you're comfortable with.",
        buttonText: 'Start',
        percentage: 0,
        minute:5,
        imagePath:'risk-tolerance.svg',
        children: []
    },
    {
        status: 'in-complete',
        title: 'Wealth Health Score',
        stepTitle: ApplicationStep.WealthHealth,
        description: 'Discover your financial fitness and where to improve here.',
        buttonText: 'Start',
        percentage: 0,
        minute:10,
        imagePath:'wealth-health-score.svg',
        children: []
    }
];

export interface StepDetail {
    status: 'fully-completed' | 'completed' | 'in-process' | 'in-complete' | '';
    title: string;
    stepTitle: ApplicationStep | FinancialCategoryType;
    description: string;
    buttonText: string;
    percentage: number;
    children: StepDetail[];
    imagePath?:string;
    minute:number;
}
