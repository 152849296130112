import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormGroup, FormRecord, ReactiveFormsModule } from '@angular/forms';

import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AnswerSelection, AnswerType, FinancialQuestion, ScreenSize } from '@acorn/util';

import { SingleQuestionComponent } from '../single-question';

import { initFormByQuestion } from '../../utils';
import { GroupQuestionComponent } from '../group-question';

@Component({
    selector: 'acorn-array-question',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        MatExpansionModule,
        SingleQuestionComponent,
        MatButtonModule,
        MatIconModule,
        GroupQuestionComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './array-question.component.html',
    styleUrls: ['./array-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayQuestionComponent implements OnInit {
    #cdr = inject(ChangeDetectorRef);

    @HostBinding('class.dual-respondent')
    @Input()
    isDualRespondent? = false;

    @Input({ required: true }) question!: FinancialQuestion;
    @Input({ required: true }) formArray!: FormArray<FormGroup | FormRecord<any>>;
    @Input() answerSelections: AnswerSelection[] = [];
    @Input() maxControl: null | number = null;
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;

    get isAbleToAddMore(): boolean {
        return (this.maxControl && this.formArray.controls.length < this.maxControl) || !this.maxControl;
    }

    protected readonly ScreenSize = ScreenSize;
    protected readonly AnswerType = AnswerType;

    addMore(): void {
        if (this.question.childrenQuestions?.length) {
            const formRecord = new FormRecord({});
            this.question.childrenQuestions.forEach((childQuestion) => {
                const form = initFormByQuestion(childQuestion);
                formRecord.addControl(childQuestion.id, form);
            });
            this.formArray.push(formRecord);
            this.#cdr.detectChanges();
        } else {
            const formControl = initFormByQuestion(this.question);
            this.formArray.push(formControl);
        }
    }

    onRemove(removedIndex: number): void {
        this.formArray.removeAt(removedIndex);
    }

    ngOnInit(): void {
        if (this.isAppComplete || this.isFileNotes) {
            this.formArray.disable();
        }
    }
}
