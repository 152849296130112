import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

import { Store } from '@ngrx/store';
import { noop } from 'rxjs';

import { SecurityActions } from './store';

import { FooterComponent, HeaderComponent } from './common/ui';
import { SecurityService } from './common/data-access';
import { UnsavedChangesService } from 'libs/feature-application/src/lib/feature-goals/data-access/unsaved-changes.service';

@Component({
    standalone: true,
    selector: 'acorn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterModule, HeaderComponent, FooterComponent],
})
export class AppComponent implements OnInit {
    securityService = inject(SecurityService);

    #router = inject(Router);
    #store = inject(Store);
    #unsavedChangesSvc = inject(UnsavedChangesService);
    #renderer = inject(Renderer2);
    #scriptAdded = false;

    ngOnInit(): void {
        this.#router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url === '/dashboard') {
                    this.removeScript();
                } else {
                    this.addScript();
                }
            }
        });
    }

    addScript() {
        if (!this.#scriptAdded) {
          const inlineScript = this.#renderer.createElement('script');
          inlineScript.innerHTML = `window.$zoho=window.$zoho || {}; $zoho.salesiq=$zoho.salesiq||{ready:function(){}}`;
          this.#renderer.appendChild(document.body, inlineScript);

          const externalScript = this.#renderer.createElement('script');
          externalScript.id = 'zsiqscript';
          externalScript.src = 'https://salesiq.zohopublic.com.au/widget?wc=5493bd018613de297bd962926d8a2af5ce59b00e010497e4a7191ad5a1130135';
          externalScript.defer = true;
          this.#renderer.appendChild(document.body, externalScript);

          this.#scriptAdded = true;
        }
      }

      removeScript() {
        const scripts = document.querySelectorAll('script[src="https://salesiq.zohopublic.com.au/widget?wc=5493bd018613de297bd962926d8a2af5ce59b00e010497e4a7191ad5a1130135"]');
        scripts.forEach((script) => {
          script.parentNode?.removeChild(script);
        });

        const inlineScripts = document.querySelectorAll('script');
        inlineScripts.forEach((script) => {
          if (script.innerHTML.includes('$zoho.salesiq')) {
            script.parentNode?.removeChild(script);
          }
        });

        this.#scriptAdded = false;
      }

    signIn(): void {
        this.#router.navigate(['/auth/sign-in']).then(noop);
    }

    signOut(): void {
        if (this.#unsavedChangesSvc.hasUnsavedChanges) {
            const confirmLogout = confirm('You have unsaved changes. Are you sure you want to log out?');
            if (!confirmLogout) {
                return;
            }
        }
        localStorage.removeItem('financialProjectionProgress');

        this.#store.dispatch(SecurityActions.signOut());
    }
}
