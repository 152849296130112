export const StrategyImages = [
    {
        title: 'Equities Focused',
        imagePath: 'assets/images/equities-portfolio.svg',
    },
    {
        title: 'Diversified',
        imagePath: 'assets/images/diversified-portfolio.svg',
    },
    {
        title: 'Super & Savings Focused',
        imagePath: 'assets/images/savings-focused-portfolio.svg',
    },
    {
        title: 'Property Oriented',
        imagePath: 'assets/images/property-portfolio.svg',
    },
    {
        title: 'Primary Residence',
        imagePath: 'assets/images/primary-portfolio.svg',
    },
];

export const assetMapping = [
    { title: 'Property', imagePath: 'assets/images/property-3x.png' },
    { title: 'Tax & Structuring', imagePath: 'assets/images/tax-3x.png' },
    {
        title: 'Wealth Protection & Insurance',
        imagePath: 'assets/images/wealth-protection-3x.png',
    },
    {
        title: 'Retirement Planning',
        imagePath: 'assets/images/retirement-3x.png',
    },
    { title: 'Estate Planning', imagePath: 'assets/images/estate-3x.png' },
    { title: 'Superannuation', imagePath: 'assets/images/super-3x.png' },
    { title: 'Income', imagePath: 'assets/images/income-3x.png' },
    {
        title: 'Savings, Budgeting & Debt',
        imagePath: 'assets/images/savings-3x.png',
    },
    {
        title: 'Shares & Investments',
        imagePath: 'assets/images/stocks-3x.png',
    },
    {
        title: 'Shares & Other Investments',
        imagePath: 'assets/images/stocks-3x.png',
    },
    { title: 'Goals', imagePath: 'assets/images/goal-3x.png' },
];

export const riskToleranceList = [
    {
        type: 'single-low',
        description: 'This means you could consider a lower risk wealth strategy, or explore low risk financial assets.',
    },
    {
        type: 'single-medium',
        description: 'This means you could consider a moderate risk wealth strategy, or explore moderate risk financial assets.',
    },
    {
        type: 'single-high',
        description: 'This means you could consider a higher risk wealth strategy, or explore higher risk financial assets.',
    },
    {
        type: 'household-low',
        description:
            'This means you could consider a lower risk wealth strategy, or explore low risk financial assets. As this is a combined score, it’s worth noting that as individuals you may have different tolerances for risk but you can use this profile and experience to help make financial decisions together.',
    },
    {
        type: 'household-medium',
        description:
            'This means you could consider a moderate risk wealth strategy, or explore moderate risk financial assets. As this is a combined score, it’s worth noting that as individuals you may have different tolerances for risk but you can use this profile and experience to help make financial decisions together.',
    },
    {
        type: 'household-high',
        description:
            'This means you could consider a higher risk wealth strategy, or explore higher risk financial assets. As this is a combined score, it’s worth noting that as individuals you may have different tolerances for risk but you can use this profile and experience to help make financial decisions together.',
    },
];

export enum PDFDownloadType {
    ThreeStrategyOption = 0,
    ChosenStrategy = 1,
    StrategyOptionOne = 2,
    StrategyOptionTwo = 3,
    StrategyOptionThree = 4,
    ThreeToSixMonthSOA = 5,
    SixToNineMonthSOA = 6,
    NineToTwelveMonthSOA = 7,
}

export interface ApplicationStrategyDocumentModelDto {
    applicationId: string;
    strategyId?: string | null;
    pdfDownloadTypeId: PDFDownloadType;
    blobUrl: string;
    documentUrl: string;
}
