<ng-container *ngIf="markAsSeen(); else welcomeTemplate">
    <acorn-vertical-stepper [selectedIndex]="selectedStep()!" (selectedIndexChange)="onSelectStep($event)">
        <cdk-step *ngFor="let category of categories" [label]="category.name" [state]="category.status | displayStepState">
            <acorn-nested-vertical-stepper
                *ngIf="category.id === selectedParentCategory()?.id"
                [parentId]="category.id"
                [activeParentId]="selectedParentCategory()?.id!"
                [selectedIndex]="selectedChildStep()!"
                (selectedIndexChange)="onSelectChildStep($event)"
            >
                <cdk-step
                    *ngFor="let subStep of category.childItems!"
                    [label]="subStep.name"
                    [state]="subStep.status === CategoryStatus.Complete ? 'done' : ''"
                ></cdk-step>
            </acorn-nested-vertical-stepper>
        </cdk-step>
    </acorn-vertical-stepper>

    <div class="step__body">
        <ng-container *ngIf="selectedChildStep() === 0">
            <acorn-setup-plan
                [currentApplication]="currentApplication"
                [allowNavigateToHome]="allowNavigateToHome"
                [applicationPlan]="applicationPlan"
                [isFileNotes]="isFileNotes"
                [isAppComplete]="isAppComplete"
                (back)="onHandleBack()"
                (next)="onBeforeHandleNext($event, true, false)"
            ></acorn-setup-plan>
        </ng-container>

        <ng-container *ngIf="selectedChildStep() === 1">
            <acorn-setup-details
                *ngIf="applicationUsers && applicationPlan"
                [applicationPlan]="applicationPlan"
                [applicationUsers]="applicationUsers"
                [isFileNotes]="isFileNotes"
                [isAppComplete]="isAppComplete"
                (back)="onHandleBack()"
                (next)="onBeforeHandleNext($event.applicationUsers, false, true)"
            ></acorn-setup-details
        ></ng-container>

        <ng-container *ngIf="applicationUsers?.length && selectedChildStep() !== 0 && selectedChildStep() !== 1">
            <ng-container [ngSwitch]="selectedTemplateType()">
                <acorn-dependants
                    *ngSwitchCase="TemplateType.MultiAnswerQuestion"
                    [questions]="selectedQuestions()"
                    [parentCategory]="selectedParentCategory()!"
                    [currentCategory]="selectedCategory()!"
                    [applicationId]="applicationId"
                    [applicationUsers]="applicationUsers"
                    [isFileNotes]="isFileNotes"
                    [applicationAboutYou]="latestApplicationAboutYou()"
                    (async)="onUpdateApplicationAboutYou($event)"
                    (back)="onHandleBack()"
                    (next)="onBeforeHandleNext()"
                    [isAppComplete]="isAppComplete"
                ></acorn-dependants>

                <acorn-last-review
                    *ngSwitchCase="TemplateType.LastReview"
                    [questions]="selectedQuestions()"
                    [parentCategory]="selectedParentCategory()!"
                    [currentCategory]="selectedCategory()!"
                    [answerSelections]="answerSelections"
                    [applicationId]="applicationId"
                    [applicationUsers]="applicationUsers"
                    [isFileNotes]="isFileNotes"
                    [applicationAboutYou]="latestApplicationAboutYou()"
                    (async)="onUpdateApplicationAboutYou($event)"
                    (back)="onHandleBack()"
                    (next)="onBeforeHandleNext()"
                    [isAppComplete]="isAppComplete"
                ></acorn-last-review>
                <acorn-common-answer
                    *ngSwitchDefault
                    [answerSelections]="answerSelections"
                    [questions]="selectedQuestions()"
                    [primaryQuestion]="selectedPrimaryQuestion()"
                    [parentCategory]="selectedParentCategory()!"
                    [currentCategory]="selectedCategory()!"
                    [applicationId]="applicationId"
                    [applicationUsers]="applicationUsers"
                    [isFileNotes]="isFileNotes"
                    [applicationAboutYou]="latestApplicationAboutYou()"
                    (async)="onUpdateApplicationAboutYou($event)"
                    (back)="onHandleBack()"
                    (next)="onBeforeHandleNext()"
                    [isAppComplete]="isAppComplete"
                ></acorn-common-answer>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #welcomeTemplate>
    <div></div>

    <acorn-welcome-screen
        imageName="w-about-you.svg"
        title="About You"
        description="Now we’d like to learn a little more about you. In this section, we’ll gather information that will help us understand your background better. By sharing details about your personal circumstances, we can tailor our advice to fit your unique situation. So let’s get started!"
        estimatedTime="3"
    >
        <acorn-application-navigation (back)="back.emit()" (next)="onInitializeStep()"></acorn-application-navigation>
    </acorn-welcome-screen>

    <div></div>
</ng-template>
