<mat-accordion>
  <mat-expansion-panel [expanded]="true" [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title
        *ngIf="
          question.childrenQuestions?.length;
          else singleTitleQuestionTemplate
        "
        [title]="question!.question.content"
      >
        {{ question!.question.content }}
      </mat-panel-title>

      <ng-template #singleTitleQuestionTemplate>
        <mat-panel-title [title]="question!.label">
          {{ question!.label }}
        </mat-panel-title>
      </ng-template>
    </mat-expansion-panel-header>

    <div class="questions">
      <ng-container
        *ngFor="let formControl of formArray.controls; index as index"
      >
        <ng-container
          *ngIf="
            question.childrenQuestions?.length;
            else singleQuestionTemplate
          "
        >
          <acorn-group-question
            [formGroup]="formControl"
            [question]="question"
            [formRecord]="$any(formControl)"
            [hasDivider]="false"
            [index]="index + 1"
            (removeClicked)="onRemove(index)"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="isAppComplete"
          >
          </acorn-group-question>
        </ng-container>

        <ng-template #singleQuestionTemplate>
          <acorn-single-question
            class="card-disabled"
            [hasRemoveButton]="true"
            [questionIndex]="index + 1"
            [expandDisabled]="true"
            [formGroup]="$any(formControl)"
            [question]="question"
            [answerSelections]="answerSelections"
            (removeClicked)="onRemove(index)"
            [isFileNotes]="isFileNotes"
            [isAppComplete]="isAppComplete"
          ></acorn-single-question>
        </ng-template>

        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngIf="isAbleToAddMore">
        <button mat-stroked-button class="add-button" (click)="addMore()"  [disabled]="isFileNotes || isAppComplete">
          Add
          <mat-icon iconPositionEnd>add</mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
