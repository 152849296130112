import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { StrategyOptionComponent } from './ui/strategy-option/strategy-option.component';
import { ApplicationStep, CustomerUser, WealthStrategyOptionsStep } from '@acorn/util';
import { NavigationComponent } from '@acorn/common-ui';
import { WealthStrategyOptionsHomeComponent } from './ui/wealth-strategy-options-home/wealth-strategy-options-home.component';
import { WealthStrategyOptionsWhatsnextComponent } from './ui/wealth-strategy-options-whatsnext/wealth-strategy-options-whatsnext.component';
import { WealthStrategyOptionsComponent } from './ui/wealth-strategy-options/wealth-strategy-options.component';
import { FeatureWealthStrategyOptionsService } from './data-access/feature-wealth-strategy-options.service';
import { MatDialog } from '@angular/material/dialog';
import { StrategyPopupComponent } from './ui/strategy-popup/strategy-popup.component';
import { Router } from '@angular/router';
import { ApplicationGoalService, ApplicationService } from '@acorn/data-access';
import { catchError, map, noop, Observable, of } from 'rxjs';
import { WhatsNextComponent } from './ui/whats-next/whats-next.component';
import { StrategyServiceHelper } from '../helpers/strategy-service.helper';

export interface StrategyDetails {
    applicationId: string;
    strategyId: string;
    strategyOption: string;
    name: string;
    description: string;
    exampleActions: string[];
    primaryBBs: string[];
    benefits: string[];
    risks: string[];
    portfolioType: string;
    risk: number;
    riskVsReturnProfile: string;
    riskDescription: string;
    selectedByCustomerDate: string;
    goals: any;
}

@Component({
    selector: 'acorn-feature-wealth-strategy-options',
    standalone: true,
    imports: [
        CommonModule,
        StrategyOptionComponent,
        NavigationComponent,
        WhatsNextComponent,
        WealthStrategyOptionsHomeComponent,
        WealthStrategyOptionsWhatsnextComponent,
        WealthStrategyOptionsComponent,
    ],
    providers: [FeatureWealthStrategyOptionsService],
    templateUrl: './feature-wealth-strategy-options.component.html',
    styleUrls: ['./feature-wealth-strategy-options.component.scss'],
})
export class FeatureWealthStrategyOptionsComponent implements OnInit {
    @Output() back = new EventEmitter<void>();
    @Output() finishedStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();

    @Output() dashboardEmit = new EventEmitter<void>();

    @Input() applicationId: any;
    @Input({ required: true }) currentSubStep!: WealthStrategyOptionsStep;
    @Input({ required: true }) latestApplicationStep!: ApplicationStep;
    @Input() currentUser!: CustomerUser;
    @Input() onlyWealthstrategyOptions!: boolean;
    @Input() isFileNotes: boolean = false;

    #viewport = inject(ViewportScroller);
    #wealthStrategyOptionsSvc = inject(FeatureWealthStrategyOptionsService);
    #dialog = inject(MatDialog);
    cd = inject(ChangeDetectorRef);
    #applicationGoalSvc = inject(ApplicationGoalService);

    #router = inject(Router);
    #applicationService = inject(ApplicationService);

    public currentStep = signal<WealthStrategyOptionsStep | null>(null);
    public strategies: any;
    public model: any;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.#setupCurrentWealthCoachStep();

        if (this.currentSubStep === WealthStrategyOptionsStep.WealthStrategyOptions) {
            this.showStrategy();
            this.getstrategiesCalculation();
            this.currentStep.set(WealthStrategyOptionsStep.WealthStrategyOptions);
        }
    }

    onHandleBack() {
        if (this.onlyWealthstrategyOptions && this.currentUser.currentApplication?.isFromDashBoardApp) {
            this.dashboardEmit.emit();
            return;
        }

        if (this.onlyWealthstrategyOptions === true) {
            window.location.href = '/dashboard';
            return;
        }

        if (this.currentStep() === WealthStrategyOptionsStep.WealthStrategyOptions) {
            this.#scrollToTop();
            this.showStrategy();
            this.getstrategiesCalculation();
            this.currentStep.set(WealthStrategyOptionsStep.WelcomeBack);
        } else if (this.currentStep() === WealthStrategyOptionsStep.WhatsNext) {
            this.#scrollToTop();
            this.showStrategy();
            this.getstrategiesCalculation();
            this.currentStep.set(WealthStrategyOptionsStep.WealthStrategyOptions);
        } else if (this.currentStep() === WealthStrategyOptionsStep.WelcomeBack) {
            this.#scrollToTop();
            this.back.emit();
        }
    }

    onHandleNext() {
        if (this.onlyWealthstrategyOptions && this.currentUser.currentApplication?.isFromDashBoardApp) {
            this.dashboardEmit.emit();
            return;
        }

        if (this.onlyWealthstrategyOptions === true) {
            window.location.href = '/dashboard';
        } else if (this.currentStep() === WealthStrategyOptionsStep.WelcomeBack) {
            this.showStrategy();
            this.getstrategiesCalculation();
            this.currentStep.set(WealthStrategyOptionsStep.WealthStrategyOptions);
        } else if (this.currentStep() === WealthStrategyOptionsStep.WealthStrategyOptions) {
            this.#scrollToTop();
            this.currentStep.set(WealthStrategyOptionsStep.WhatsNext);
        } else if (this.currentStep() === WealthStrategyOptionsStep.WhatsNext) {
            if (this.currentUser.currentApplication?.isFromDashBoardApp) {
                this.dashboardEmit.emit();
            }
            this.gotoDashboard();
            // this.#scrollToTop();
            // this.finishedStep.emit({ isUpdateApplicationProgress: true });
        }
    }

    gotoDashboard(): void {
        this.#applicationService.updateCurrentProgress(ApplicationStep.Dashboard, null, null).subscribe(() => {
            window.location.href = '/dashboard';
        });
    }

    #setupCurrentWealthCoachStep() {
        const currentApplication = this.currentUser.currentApplication;

        const currentStep = <WealthStrategyOptionsStep | null>(<unknown>currentApplication?.currentSubStep);

        this.currentStep.set(this.#getCurrentStep(currentStep));
    }

    #getCurrentStep(currentStep: WealthStrategyOptionsStep | null): WealthStrategyOptionsStep | null {
        currentStep = WealthStrategyOptionsStep.WelcomeBack;
        return currentStep;
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }

    public getstrategiesCalculation() {
        this.#wealthStrategyOptionsSvc.GetStrategiesCalculation(this.currentUser.currentApplication?.id).subscribe(
            (res: any) => {
                this.strategies = res?.map(
                    (item: any) =>
                        ({
                            applicationId: item.applicationId,
                            strategyId: item.strategyId,
                            strategyOption: item.strategyOption,
                            name: item.strategy.name,
                            description: item.strategy.description,
                            longDescription: item.strategy.longDescription,
                            riskVsReturnProfile: item.strategy.riskVsReturnProfile,
                            riskDescription: item.strategy.riskDescription,
                            selectedByCustomerDate: item.selectedByCustomerDate,
                            benefits: [
                                item.strategy.benefits1,
                                item.strategy.benefits2,
                                item.strategy.benefits3,
                                item.strategy.benefits4,
                                item.strategy.benefits5,
                            ],
                            exampleActions: [
                                item.strategy.exampleAction1,
                                item.strategy.exampleAction2,
                                item.strategy.exampleAction3,
                                item.strategy.exampleAction4,
                                item.strategy.exampleAction5,
                            ].filter(Boolean),
                            primaryBBs: [
                                item.strategy.primaryBB1,
                                item.strategy.primaryBB2,
                                item.strategy.primaryBB3,
                                item.strategy.primaryBB4,
                                item.strategy.primaryBB5,
                            ].filter(Boolean),
                            risks: [
                                item.strategy.risk1,
                                item.strategy.risk2,
                                item.strategy.risk3,
                                item.strategy.risk4,
                                item.strategy.risk5,
                            ].filter(Boolean),
                            portfolioType: item.strategy.portfolioType,
                            risk: item.strategy.risk,
                            goals: [],
                        } as StrategyDetails)
                );

                this.strategies.forEach((strategy: StrategyDetails) => {
                    this.getGoals(strategy.strategyId).subscribe((goals: any) => {
                        strategy.goals = goals;

                        this.cd.markForCheck();
                    });
                });

                this.cd.markForCheck();

                setTimeout(() => {
                    if (this.model && this.strategies) {
                        this.model.close();
                    }
                }, 500);
            },
            (err: any) => {
                console.error('Error fetching strategies calculation:', err);
            }
        );
    }

    showStrategy() {
        this.model = this.#dialog.open(StrategyPopupComponent, {
            width: '450px',
            height: '450px',
            disableClose: true,
            hasBackdrop: true,
            panelClass: 'financial-popup-container',
        });

        this.model.afterClosed().subscribe(() => {
            this.#scrollToTop();
        });
    }

    public getGoals(strategyId: string): Observable<any> {
        return this.#applicationGoalSvc.getApplicationGoal(this.applicationId, strategyId).pipe(
            map((res: any) => res.data),
            catchError((err: any) => {
                console.error(err);
                return of([]);
            })
        );
    }
}
