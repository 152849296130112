<ng-container *ngIf="markAsSeen(); else welcomeTemplate">
    <acorn-vertical-stepper [selectedIndex]="selectedStep()!" (selectedIndexChange)="onSelectStep($event)">
        <cdk-step *ngFor="let category of categories" [label]="category.name" [state]="category.status | displayStepState">
            <acorn-nested-vertical-stepper
                *ngIf="category.id === selectedParentCategory()?.id!"
                [parentId]="category.id"
                [activeParentId]="selectedParentCategory()?.id!"
                [selectedIndex]="selectedChildStep()!"
                (selectedIndexChange)="onSelectChildStep($event)"
            >
                <cdk-step
                    *ngFor="let subStep of category.childItems!"
                    [label]="subStep.name"
                    [state]="subStep.status === CategoryStatus.Complete ? 'done' : ''"
                ></cdk-step>
            </acorn-nested-vertical-stepper>
        </cdk-step>
    </acorn-vertical-stepper>

    <div class="step__body">
        <ng-container *ngIf="selectedStep() === 1">
            <acorn-risk-tolerance-outcome [currentUser]="currentUser" [applicationId]="applicationId"></acorn-risk-tolerance-outcome>
            <acorn-application-navigation (back)="onBack()" (next)="onBeforeHandleNext()"> </acorn-application-navigation>
        </ng-container>
        <ng-container *ngIf="selectedStep() !== 1">
            <ng-container [ngSwitch]="selectedTemplateType()">
                <ng-container *ngSwitchDefault>
                    <acorn-common-answer
                        [answerSelections]="answerSelections"
                        [questions]="selectedQuestions()"
                        [primaryQuestion]="selectedPrimaryQuestion()"
                        [parentCategory]="selectedParentCategory()!"
                        [currentCategory]="selectedCategory()!"
                        [applicationId]="applicationId"
                        [applicationUsers]="applicationUsers"
                        [isFileNotes]="isFileNotes"
                        [isAppComplete]="isAppComplete"
                        [applicationAboutYou]="latestApplicationAboutYou()"
                        (async)="onUpdateApplicationAboutYou($event)"
                        (back)="onHandleBack()"
                        (next)="onBeforeHandleNext()"
                    ></acorn-common-answer>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #welcomeTemplate>
    <div></div>

    <acorn-welcome-screen
        imageName="w-risk-tolerance.svg"
        title="Risk Tolerance"
        description="Now we know more about your finances, it's time to understand your attitude to risk when building wealth. Complete the questions to find out your risk return </br> profile. We'll use these insights to offer the most appropriate wealth strategy </br> options for you to chose from."
        estimatedTime="5"
    >
        <acorn-application-navigation (back)="back.emit()" (next)="onInitializeStep()"> </acorn-application-navigation>
    </acorn-welcome-screen>

    <div></div>
</ng-template>
